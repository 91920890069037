<div class="action-container" *ngIf="!likedComic">
  <p class="text-center mb-2">
    <span *ngIf="ctaList.includes('signin') && !ctaList.includes('subscribe')"> You must be signed in to comment.
    </span>
    <span *ngIf="!ctaList.includes('signin') && ctaList.includes('subscribe')"> You must be a subscriber to comment.
    </span>
    <span *ngIf="ctaList.includes('signin') && ctaList.includes('subscribe')"> You must be a subscriber and signed in to
      comment. </span>
  </p>
  <div class="btn-container pb-0">
    <button class="secondary-btn" aria-label="Open" (click)="modalRef.hide()">
      Got it
    </button>
    &nbsp;
    <button *ngIf="ctaList.includes('subscribe')" class="btn tv-btn-red" aria-label="Open" [routerLink]="['/tinyview/subscribe']"
      (click)="modalRef.hide()">
      <span *ngIf="!willShowLoader">Subscribe</span>
      <div class="loader-bar" *ngIf="willShowLoader"></div>
    </button>
    &nbsp;
    <button *ngIf="ctaList.includes('signin')" class="btn tv-btn-red" aria-label="Open" (click)="modalRef.hide()"
      [routerLink]="['/signin/phone/number']">
      <span *ngIf="!willShowLoader">Sign In</span>
      <div class="loader-bar" *ngIf="willShowLoader"></div>
    </button>
  </div>
  <div class="sms-response" *ngIf="smsResponse != null">
    <span
      [ngClass]="{'success-response': smsResponse =='Link sent successfully.', 'error-response': smsResponse != 'Link sent successfully.' }">{{smsResponse}}</span>
  </div>
</div>

<div class="action-container" *ngIf="likedComic">
  <p class="action-heading text-center">You need to Sign In first</p>
  <div class="btn-container">
    <button class="secondary-btn" aria-label="Open" (click)="modalRef.hide()">
      Cancel
    </button>
    &nbsp;
    <button class="btn tv-btn-red" aria-label="Open" (click)="goToSignInPage()">
      Sign In
    </button>
  </div>
</div>
<ng-template #modal>
  <div class="modal-header">
    <h4 class="modal-title w-100 text-center">Send Me The Link</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="img-container">
      <img src="/assets/images/tinyview-logo-only-mark-square-rounded.png" alt="tinyview logo">
    </div>
    <div class="desc-container">
      <div class="desc-wrapper">
        <div class="title">
          Tinyview Comics
        </div>
        <div class="desc">
          You will receive a one-time text to download the app.
        </div>
      </div>
      <div class="form-container">
        <form [formGroup]="myform" (ngSubmit)="sendSMS('')">
          <div class="form-group">
            <label for="phone">Phone Number:</label>
            <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="false"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="10" [phoneValidation]="true"
              [separateDialCode]="separateDialCode" name="phone" formControlName="phone">
            </ngx-intl-tel-input>
          </div>
          <div class="form-group">
            <button type="submit" class="btn app-btn" [disabled]="!myform.valid">
              <span *ngIf="!willShowLoader">Send Me The Link</span>
              <div class="loader-bar" *ngIf="willShowLoader"></div>
            </button>
          </div>
          <div class="sms-response" *ngIf="smsResponse != null">
            <span
              [ngClass]="{'success-response': smsResponse =='Link sent successfully.', 'error-response': smsResponse != 'Link sent successfully.' }">{{smsResponse}}</span>
          </div>
        </form>
      </div>
      <div class="footer-wrapper">
        <span>By providing your phone number, you agree to receive a one-time automated text message with a link to get
          the app. Standard messaging rates may apply.</span>
      </div>
    </div>
  </div>
</ng-template>
