import { Injectable } from "@angular/core"
import { Meta, Title } from "@angular/platform-browser"

import { StoryFeedService } from "./story-feed.service"
import { ComicService } from "./comic.service"
import { environment } from "../../environments/environment"
import { INFLUENCE_PAGE } from "../constants/influence.constants"
import { title } from "process"
import { SessionStorageService } from "./session-storage.service"
import {
  CacheService,
  SERIES_LIST_DATA,
  SERIES_DATA,
  STORY_DATA,
} from "./cache.service"

export type DOCUMENT_TYPE = {
  location: { pathname: string, search: string }
  createElement: Function
  head: { appendChild: Function }
}

type META_DATA_RES = {
  title: string
  description?: string
  comments?: string
  comment?: string
  image?: string
  ogImage?: string
}

const PP_PAGE = {
  title: "Privacy Policy",
  description: "Privacy Policy",
  ogImage:
    "https://storage.googleapis.com/tinyview-d78fb.appspot.com/tinyview/tinyview-fb-cover.jpg",
}

const TERMS_PAGE = {
  title: "Terms and Conditions",
  description: "Terms and Conditions",
  ogImage:
    "https://storage.googleapis.com/tinyview-d78fb.appspot.com/tinyview/tinyview-fb-cover.jpg",
}

const ALERTS_PAGE = {
  title: "Manage Alerts",
  description: "Manage Alerts",
  ogImage:
    "https://storage.googleapis.com/tinyview-d78fb.appspot.com/tinyview/tinyview-fb-cover.jpg",
}

@Injectable({
  providedIn: "root",
})
export class PageMetaService {
  constructor(
    private comicService: ComicService,
    private feedService: StoryFeedService,
    private sessionStorageService: SessionStorageService,
    private cacheService: CacheService
  ) {}

  private isSeriesPage = (pathVal, series) => {
    let isSeriesPage = false
    if ([...series].includes(pathVal)) {
      isSeriesPage = true
    }
    return isSeriesPage
  }

  private isStoryPage = (pathVal) => {
    const regexPattern = /\/story\/[a-zA-Z0-9]+/gi
    return regexPattern.test(pathVal)
  }

  private isComicPage = (pathVal) => {
    // i.e path - "/heart-and-brain/2022/01/26/sleepless"
    const regexPattern =
      /\/([a-z0-9-]+)\/([0-9]+)\/([0-9]+)\/([0-9]+)\/([a-z0-9-]+)/gi

    let isValidPage = regexPattern.test(pathVal);
    if (!isValidPage) {
      const urls = this.URLS_MAP;
      const isComicPageUrl = ![urls.HOME, urls.FOLLOWING, urls.ALL_SERIES, urls.SUBSCRIBE, urls.INFLUENCE, urls.TERMS_COND, urls.PRIVACY_POLICY, urls.MANAGE_ALERTS,'/edit'].includes(pathVal)

      if (isComicPageUrl && !this.isStoryPage(pathVal) && pathVal.split('/').length > 2) {
        isValidPage = true;
      }
    }
    return isValidPage;
  }

  private URLS_MAP = {
    HOME: "/",
    FOLLOWING: "/",
    ALL_SERIES: "/tinyview/comic-series-directory",
    SUBSCRIBE: "/tinyview/subscribe",
    INFLUENCE: "/tinyview/influence-points",
    TERMS_COND: "/terms-conditions",
    PRIVACY_POLICY: "/privacy-policy",
    MANAGE_ALERTS: "/manage-alerts",
    SERIES_PAGE: this.isSeriesPage,
    STORY_PAGE: this.isStoryPage,
    COMIC_PAGE: this.isComicPage,
  }

  private checkPageByKey = (
    pathName: string,
    pageValidator: string | Function,
    seriesList?: string[]
  ) => {
    if (!pageValidator) {
      throw new Error("getPageFromUrl: pageKey cannot be empty")
    }

    let pathValue = pathName ? pathName : "/"

    let isSimilarPage = false

    if (typeof pageValidator == "string") {
      if (pageValidator == "/") {
        isSimilarPage = pageValidator === pathValue
      } else {
        isSimilarPage = pathValue.indexOf(pageValidator) > -1
      }
    }

    if (typeof pageValidator == "function") {
      isSimilarPage = pageValidator(pathValue, seriesList)
    }
    return isSimilarPage
  }

  private setMetaInfo(
    data: META_DATA_RES,
    docObj: DOCUMENT_TYPE,
    titleObj: Title,
    metaObj: Meta
  ) {
    const CURRENT_URL = "currentUrl"
    const OG_IMG = "ogImage"
    const DESCRIPTION = "description"
    const COMIC_IMG_API = environment.API_END_POINT
    const DOMAIN_NAME = environment.DOMAIN_NAME

    const metaTagsMapping = [
      {
        tagName: "twitter:description",
        respKeyName: DESCRIPTION,
        fallbackOne: "comments",
        fallbackTwo: "comment",
      },
      {
        tagName: "twitter:image",
        respKeyName: OG_IMG,
        fallbackOne: "image",
      },
      {
        tagName: "twitter:title",
        respKeyName: "title",
      },
      {
        tagName: "og:title",
        respKeyName: "title",
      },
      {
        tagName: "og:description",
        respKeyName: DESCRIPTION,
        fallbackOne: "comments",
        fallbackTwo: "comment",
      },
      {
        tagName: "og:image",
        respKeyName: OG_IMG,
        fallbackOne: "image",
      },
      {
        tagName: "og:url",
        respKeyName: CURRENT_URL,
      },
    ]

    titleObj.setTitle(data.title.trim())
    let metaStoreObj = {}
    for (const metaProperty of metaTagsMapping) {
      let content =
        data[metaProperty.respKeyName] ||
        data[metaProperty.fallbackOne] ||
        data[metaProperty.fallbackTwo]

      if (metaProperty.respKeyName == CURRENT_URL) {
        content = DOMAIN_NAME + docObj.location.pathname
        let link: HTMLLinkElement = docObj.createElement("link")
        link.setAttribute("rel", "canonical")
        link.setAttribute("href", content)
        docObj.head.appendChild(link)
      }

      if (
        metaProperty.respKeyName == OG_IMG &&
        content.indexOf("http") === -1
      ) {
        if (content.charAt(0) !== "/") {
          content = "/" + content
        }
        content = COMIC_IMG_API + content
      }

      // convert html desc to string
      if (metaProperty.respKeyName == DESCRIPTION) {
        content = content.replace(/<[^>]+>/g, '');
      }

      metaObj.updateTag({
        property: metaProperty.tagName,
        content: content.trim(),
      })
      metaStoreObj[metaProperty.tagName] = content.trim()
    }

    this.sessionStorageService.setItem("metaTagsInfo", metaStoreObj)
  }

  public async setMetaTags(
    docObj: DOCUMENT_TYPE,
    titleObj: Title,
    metaObj: Meta
  ) {
    let urlPath = docObj.location.pathname

    let isAllSeriesPage = this.checkPageByKey(urlPath, this.URLS_MAP.ALL_SERIES)
    let isSubscribePage = this.checkPageByKey(urlPath, this.URLS_MAP.SUBSCRIBE)
    let isHomePage = this.checkPageByKey(urlPath, this.URLS_MAP.HOME)
    let isFollowingPage = this.checkPageByKey(urlPath, this.URLS_MAP.FOLLOWING)
    let isStoryPage = this.checkPageByKey(urlPath, this.URLS_MAP.STORY_PAGE)
    let isComicPage = this.checkPageByKey(urlPath, this.URLS_MAP.COMIC_PAGE)
    let isInfluencePage = this.checkPageByKey(urlPath, this.URLS_MAP.INFLUENCE)
    let isTermsPage = this.checkPageByKey(urlPath, this.URLS_MAP.TERMS_COND)
    let isPrivacyPolicyPage = this.checkPageByKey(
      urlPath,
      this.URLS_MAP.PRIVACY_POLICY
    )
    let isAlertsPage = this.checkPageByKey(urlPath, this.URLS_MAP.MANAGE_ALERTS)

    if (isStoryPage) {
      const splitArr = urlPath.split("/")
      const storyID = splitArr[splitArr.length - 1]

      let getStory;
      if (storyID === 'action') {
        const urlParams = new URLSearchParams(docObj.location.search);
        const actionURL = urlParams.get('actionURL');

        getStory = this.feedService.getStory(actionURL);
      } else {
        getStory = this.feedService.getStoryByID(storyID);
      }

      const key = STORY_DATA + "_" + storyID;
      let storyData = this.cacheService.get(key)
      if (!storyData) {
        getStory.then(async (resp) => {
          const metaInfo = resp.data.data
          this.cacheService.set(key, metaInfo)
          this.setMetaInfo(metaInfo, docObj, titleObj, metaObj)
        })
      } else {
        console.log('SERVED FROM CACHE FOR: isStoryPage');
        this.setMetaInfo(storyData, docObj, titleObj, metaObj)
      }
    } else if (isAllSeriesPage || isComicPage || isSubscribePage) {
      const key = urlPath
      let respData = this.cacheService.get(key)
      if (!respData) {
        this.comicService
          .getComicChapters(urlPath, "")
          .subscribe(async (resp) => {
            const metaInfo = resp.comics
            if (isComicPage) {
              metaInfo.ogImage =
                docObj.location.pathname + "/" + metaInfo.ogImage

              // give priority to comments in comics
              metaInfo.description = metaInfo.comments
                ? metaInfo.comments
                : metaInfo.description
            }
            this.cacheService.set(key, metaInfo)
            this.setMetaInfo(metaInfo, docObj, titleObj, metaObj)
          })
      } else {
        console.log('SERVED FROM CACHE FOR: isAllSeriesPage || isComicPage || isSubscribePage');
        this.setMetaInfo(respData, docObj, titleObj, metaObj)
      }
    } else if (isHomePage || isFollowingPage) {
      const key = urlPath
      let respData = this.cacheService.get(key)
      if (!respData) {
        this.comicService
          .getNewsFeedChapters(urlPath)
          .subscribe(async (resp) => {
            const metaInfo = resp.comics
            this.cacheService.set(key, metaInfo)
            this.setMetaInfo(metaInfo, docObj, titleObj, metaObj)
          })
      } else {
        console.log('SERVED FROM CACHE FOR: isHomePage || isFollowingPage');
        this.setMetaInfo(respData, docObj, titleObj, metaObj)
      }
    } else if (isInfluencePage) {
      const metaInfo = {
        title: INFLUENCE_PAGE.title,
        description: INFLUENCE_PAGE.description,
        ogImage: INFLUENCE_PAGE.image,
      }
      this.setMetaInfo(metaInfo, docObj, titleObj, metaObj)
    } else if (isPrivacyPolicyPage) {
      const metaInfo = {
        title: PP_PAGE.title,
        description: PP_PAGE.description,
        ogImage: PP_PAGE.ogImage,
      }
      this.setMetaInfo(metaInfo, docObj, titleObj, metaObj)
    } else if (isTermsPage) {
      const metaInfo = {
        title: TERMS_PAGE.title,
        description: TERMS_PAGE.description,
        ogImage: TERMS_PAGE.ogImage,
      }
      this.setMetaInfo(metaInfo, docObj, titleObj, metaObj)
    } else if (isAlertsPage) {
      const metaInfo = {
        title: ALERTS_PAGE.title,
        description: ALERTS_PAGE.description,
        ogImage: ALERTS_PAGE.ogImage,
      }
      this.setMetaInfo(metaInfo, docObj, titleObj, metaObj)
    } else {
      let seriesListData = this.cacheService.get(SERIES_LIST_DATA)
      if (!seriesListData) {
        this.comicService.getComicTitle().subscribe(async (resp) => {
          const seriesList = Object.keys(resp || {}).map((keys) => `/${keys}`)
          this.cacheService.set(SERIES_LIST_DATA, seriesList)
          this.getSeriesListData(seriesList, urlPath, docObj, titleObj, metaObj)
        })
      } else {
        console.log('SERVED FROM CACHE FOR: SERIES_LIST_DATA');
        this.getSeriesListData(
          seriesListData,
          urlPath,
          docObj,
          titleObj,
          metaObj
        )
      }
    }
  }

  private getSeriesListData(
    seriesList: string[],
    urlPath: string,
    docObj: DOCUMENT_TYPE,
    titleObj: Title,
    metaObj: Meta
  ): void {
    let isSeriesPage = this.checkPageByKey(
      urlPath,
      this.URLS_MAP.SERIES_PAGE,
      seriesList
    )

    if (isSeriesPage) {
      const SERIES_DATA_KEY = SERIES_DATA + "_" + urlPath
      let seriesData = this.cacheService.get(SERIES_DATA_KEY)
      if (!seriesData) {
        this.comicService
          .getNewsFeedChapters(urlPath)
          .subscribe(async (resp) => {
            const metaInfo = resp.comics
            // need to be fixed on json
            if (isSeriesPage) {
              let appendUrl =
                metaInfo.ogImage.indexOf(docObj.location.pathname) > -1
                  ? ""
                  : docObj.location.pathname + "/"
              metaInfo.ogImage = appendUrl + metaInfo.ogImage
            }
            this.cacheService.set(SERIES_DATA_KEY, metaInfo)
            this.setMetaInfo(metaInfo, docObj, titleObj, metaObj)
          })
      } else {
        console.log('SERVED FROM CACHE FOR: SERIES_DATA');
        this.setMetaInfo(seriesData, docObj, titleObj, metaObj)
      }
    }
  }
}
