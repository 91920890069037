import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NewComicService } from 'src/app/new-comic.service';
import { ComicService } from 'src/app/services/comic.service';
import { MenuComponent } from 'src/app/menu/menu.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { isAnonymousUser } from 'src/app/utilities/common.util';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-bottom-nav-mobile',
  templateUrl: './bottom-nav-mobile.component.html',
  styleUrls: ['./bottom-nav-mobile.component.scss', './../../pages/comic-wrapper/comic-wrapper.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'

      })),
      state('out', style({
        transform: 'translate3d(100%,0,0)',
        'display': 'none'
      })),
      transition('in => out', animate('1ms ease-in-out')),
      transition('out => in', animate('1ms ease-in-out'))
    ]),
  ]
})
export class BottomNavMobileComponent implements OnInit {
  public currentUrl: string;
  public activeIcon: string;

  /// old Slider
  @ViewChild(MenuComponent, { static: false }) chviewChild: MenuComponent;
  public menuState: string = 'out';
  public name: string;
  public clickedOutside: boolean = true;
  userImage: any;
  userName: any;
  userBadges: any;
  userPhoneNumber: any;
  isSubscribed: boolean;
  manageSubscriptionObj: any;
  public previousItem: string;

  iconUrls: { [key: string]: string } = {
    signpost: '../../../assets/images/new-icons/signpost-100.png',
    tickbox: '../../../assets/images/new-icons/tick-box-100.png',
    home: '../../../assets/images/new-icons/home-100.png',
    user: '../../../assets/images/new-icons/user-menu-male-100.png',
    alarm: '../../../assets/images/new-icons/alarm-100.png'
  };
  iconUrlsDark: { [key: string]: string } = {
    signpost: '../../../assets/images/new-icons/signpost-100-2.png',
    tickbox: '../../../assets/images/new-icons/tick-box-100-2.png',
    home: '../../../assets/images/new-icons/home-100-2.png',
    user: '../../../assets/images/new-icons/user-menu-male-100-2.png',
    alarm: '../../../assets/images/new-icons/alarm-100-2.png'

  };
  isMobile: boolean;
  inviteFriendsRef: any;
  isAnonymous: any;
  isIncompleteProfile: boolean;
  unreadCount: string;

  constructor(
    private router: Router,
    private location: Location,
    private newComicService: NewComicService,
    private localStorageService: LocalStorageService,
    private modalService: BsModalService,
    private comicService: ComicService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit() {
    this.isAnonymous = isAnonymousUser();
    this.isMobile = this.newComicService.isMobileView();
    this.activeIcon =
      (this.location.path() === '' &&
        this.localStorageService.getItem('selectedMenuItem') === 'following')
        ? '/following'
        : this.location.path();
    this.getUserDetails();
    this.newComicService.getsidebarItemSelected().subscribe((val) => {
      if (val && !val.sameItemClicked) {
        if (val && val.menuItem === 'home') {
          this.activeIcon = '';
          this.localStorageService.setItem('selectedMenuItem', 'home');
        } else if (val && val.menuItem === 'following') {
          this.activeIcon = '/following';
          this.localStorageService.setItem('selectedMenuItem', 'following');
        }
      }
    }, err => {
      this.activeIcon = '';
    })

    // refresh login data on successfu signin
    this.newComicService.getRefreshLoginDataEvent().subscribe(() => {
      this.getUserDetails();
    });

    // code to handle same menu click for home and following page for smart scrolling purpose
    if (!this.previousItem) {
      if (['', '/'].includes(this.activeIcon)) {
        this.previousItem = 'home';
      }
      if (this.activeIcon === '/following') {
        this.previousItem = 'tickbox';
      }
      if (this.activeIcon === '/tinyview/comic-series-directory') {
        this.previousItem = 'signpost';
      }
    }
    this.setUnseenNotificationCount();
  }

  private setUnseenNotificationCount() {
    this.notificationService.getNotification(1, '', true).then( res => {
      if (this.activeIcon !== '/notifications' && res.unReadCount > 0) {
        this.unreadCount = Number(res.unReadCount) > 99 ? `99+` : String(res.unReadCount);
      } else {
        this.unreadCount = '0';
        this.notificationService.updateLastSeenNotificationAt(Date.now());
      }
    });
  }

  public getUserDetails() {
    this.comicService.getUserDetails().then(res => {
      this.isIncompleteProfile = !this.isAnonymous && (!res.data.displayName || !res.data.gender);
      this.setUserDetails(res);
    });
  }

  public navigateTo(icon: string, event?: any): void {
    event && event.preventDefault(); // to stop it from reloading the page as anchor tag does this sometimes
    // code to handle same menu click for home and following page for smart scrolling purpose
    const sameItemClicked = this.previousItem === icon;
    if (sameItemClicked && !['tickbox', 'home', 'signpost'].includes(icon)) {
      return;
    }

    this.activeIcon = icon;
    // For navigation
    switch (icon) {
      case 'home':
        this.newComicService.setsidebarItemSelected({ menuItem: 'home', sameItemClicked: sameItemClicked });
        this.activeIcon = '';
        if (sameItemClicked) return; // stopping navigation if same home is clicked
        this.router.navigate(['']);
        break;
      case 'signpost':
        this.newComicService.setsidebarItemSelected({ menuItem: '/tinyview/comic-series-directory', sameItemClicked: sameItemClicked });
        this.activeIcon = '/tinyview/comic-series-directory';
        if (sameItemClicked) return; // stopping navigation if same following is clicked
        this.router.navigate(['/tinyview/comic-series-directory']);
        break;
      case 'tickbox':
        this.newComicService.setsidebarItemSelected({ menuItem: 'following', sameItemClicked: sameItemClicked });
        this.activeIcon = '/following';
        if (sameItemClicked) return; // stopping navigation if same following is clicked
        this.router.navigate(['']);
        break;
      case 'alarm':
        // Call API: updateLastSeenNotificationAt
        this.notificationService.updateLastSeenNotificationAt(Date.now());
        this.router.navigate(['/notifications']);
        break;
      default:
        this.newComicService.setsidebarItemSelected('home');
        this.router.navigate(['']);
        break;
    }

    this.previousItem = icon;
  }

  public getIconUrl(icon: string): string {
    switch (icon) {
      case '/tinyview/comic-series-directory':
        return this.activeIcon === icon ? this.iconUrlsDark['signpost'] : this.iconUrls['signpost']
        break;
      case '/following':
        return this.activeIcon === icon ? this.iconUrlsDark['tickbox'] : this.iconUrls['tickbox']
        break;
      case '/account':
        return this.activeIcon === icon ? this.iconUrlsDark['user'] : this.iconUrls['user']
        break;
      case '/notifications':
        return this.activeIcon === icon ? this.iconUrlsDark['alarm'] : this.iconUrls['alarm']
        break;
      default:
        return this.activeIcon === '' ? this.iconUrlsDark['home'] : this.iconUrls['home']
        break;
    }
  }

  ///  For Old slider
  public outsideClick(hasClickedOutside) {
    if (this.menuState == 'in') {
      this.name = hasClickedOutside ? 'clicked inside' : 'clicked outside';
      if (this.name == 'clicked outside') {
        this.menuState = 'out'
      }
    }
  }

  private setUserDetails(user) {
    this.userImage = user.data.photoURL;
    this.userName = user.data.displayName;
    this.userBadges = user.data.badges;
    this.userPhoneNumber = user.data.phoneNumber ? user.data.phoneNumber.substr(user.data.phoneNumber.length - 4) : null;
    const subDetails = user.data.subscriptions.length && user.data.subscriptions[0];
    this.isSubscribed = true;
    switch (subDetails && subDetails.store.toLowerCase()) {
      case "stripe":
        this.comicService.createPortalLink().then((url) => {
          if (url) {
            this.manageSubscriptionObj = { url: url, target: "_blank" };
          }
        }).catch((err) => {
          console.log(err);
        })
        break;
      case "apple":
      case "ios":
        this.manageSubscriptionObj = { url: "https://apps.apple.com/account/subscriptions", target: "_blank" };
        break;
      case "android":
      case "google":
        this.manageSubscriptionObj = { url: "https://play.google.com/store/account/subscriptions", target: "_blank" };
        break;
      default:
        this.manageSubscriptionObj = { url: "tinyview/subscribe", target: "_self", internalRedirection: true };
        this.isSubscribed = false;
        break;
    }
  }

  public toggleMenu() {
    this.menuState = this.menuState == 'out' ? 'in' : 'out';
    this.clickedOutside = false;
  }

  public parentCall() {
    this.chviewChild.ngOnInit();
  }

  public openInviteFriendsModal(template: TemplateRef<any>) {
    const isMobile = this.isMobile && !this.isAnonymous ? ' mobile-modal' : '';
    this.inviteFriendsRef = this.modalService.show(template, Object.assign({}, {
      class: `modal-md${isMobile}`,
      ignoreBackdropClick: false,
      animation: !isMobile
    }));
  }

  public closeInviteFriendsModal() {
    this.inviteFriendsRef.hide();
  }
}