import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { absoluteSignUpURL } from "src/app/constants/common.constants";
import { NewComicService } from "src/app/new-comic.service";
import { ComicService } from "src/app/services/comic.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PanelService } from "src/app/services/panel/panel.service";


@Component({
  selector: 'app-signin-success',
  templateUrl: './signin-success.component.html',
  styleUrls: ['./signin-success.component.scss', './../../signin/signin.component.scss']
})
export class SigninSuccessComponent implements OnInit {
  message: string;
  comicSeries: string;
  currentDevice: string;
  isMobile: any;
  inviteFriendsRef: any;
  isSubscribed: any;
  redirectionType: string;
  constructor(
    private readonly panelService: PanelService,
    private route: ActivatedRoute,
    private readonly comicService: ComicService,
    private readonly newComicService: NewComicService,
    private modalService: BsModalService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platform: object
  ) {
  }

  ngOnInit(): void {
    this.redirectionType = this.route.snapshot.queryParams.redirectionType;
    this.message = this.route.snapshot.queryParams.message;
    this.comicSeries = this.route.snapshot.queryParams.comic;
    this.currentDevice = this.comicService.getOperatingSystem();
    this.isMobile = this.newComicService.isMobileView();
    this.isSubscribed = this.localStorageService.getItem('hasSubscription') || false;
    this.toastr.success("As a signed in user, you have unlimited access now.");
  }

  public successfulLogin() {
    let queryParams = {};
    if (this.message) {
      queryParams = { queryParams: { message: this.message } };
    }
    if (this.redirectionType === 'friend_request') {
      return this.router.navigate(['friend-request']);
    }
    if (this.comicSeries && !this.comicSeries.includes(absoluteSignUpURL)) {
      return this.router.navigate([this.comicSeries], queryParams);
    } else {
      this.router.navigate(['']);
    } 
  }

  public getImageURL(image: string) {
    return this.panelService.getImgUrl(image, '');
  }

  public openInviteFriendsModal(template: TemplateRef<any>) {
    const isMobile = this.isMobile ? ' mobile-modal' : '';
    this.inviteFriendsRef = this.modalService.show(template, Object.assign({}, {
      class: `modal-md${isMobile}`,
      ignoreBackdropClick: false,
      animation: !isMobile
    }));
  }

  public closeInviteFriendsModal() {
    this.inviteFriendsRef.hide();
  }
}