<div (appOutsideClick)=outsideClick($event) [@slideInOut]="menuState" class="w-nav-overlay"
  [ngClass]="{'menu': true}">
  <nav role="navigation" class="nav-menu w-nav-menu nav-menu-bg default-shadow">
    <app-menu [userPhoneNumber]="userPhoneNumber" [userImage]="userImage" [userName]="userName" [isIncompleteProfile]="isIncompleteProfile"
      [manageSubscriptionObj]="manageSubscriptionObj" [userBadges]="userBadges" [isSubscribed]="isSubscribed"
      (parentCall)="parentCall()" (toggleMenu)="toggleMenu()" (openInviteFriends)="openInviteFriendsModal(inviteFriendsModal)"></app-menu>
  </nav>
</div>
<div class="d-flex px-3 pt-2 justify-content-between text-center nav-container">
  <a (click)="navigateTo('home', $event)" class="text-center" href="/">
    <img class="icons" [src]="getIconUrl('home')" alt="home-icon">
    <p class="pt-1 body-mini" [class.text-dark]="activeIcon === ''">Home</p>
  </a>
  <a (click)="navigateTo('tickbox', $event)" href="/">
    <img class="icons" [src]="getIconUrl('/following')" alt="following-icon">
    <p class="pt-1 body-mini" [class.text-dark]="activeIcon === '/following'">Following</p>
  </a>
  <a (click)="navigateTo('signpost', $event)">
    <img class="icons" [src]="getIconUrl('/tinyview/comic-series-directory')" alt="directory-icon">
    <p class="pt-1 body-mini" [class.text-dark]="activeIcon === '/tinyview/comic-series-directory'">All Series</p>
  </a>
  <a (click)="navigateTo('alarm')" class="position-relative">
    <div class="circle" *ngIf="unreadCount && unreadCount != '0'" [class.oval]="unreadCount === '99+'">
      <span class="number">{{unreadCount}}</span>
    </div>
    <img class="icons" [src]="getIconUrl('/notifications')" alt="alarm-icon">
    <p class="pt-1 body-mini" [class.text-dark]="activeIcon === '/notifications'">Notifications</p>
  </a>
  <a (appOutsideClick)=outsideClick($event) class="toggle-btn pt-0" (click)="toggleMenu()">
    <img *ngIf="isIncompleteProfile" src="../../assets/images/new-icons/icons8-box-important-100.png" width="16px" height="16px" class="not-icon ml-2">
    <img class="icons" [src]="getIconUrl('/account')" alt="user-icon">
    <p class="pt-1 body-mini">Account</p>
  </a>
</div>

<ng-template #inviteFriendsModal>
  <app-invite-friends [isSubscribed]="isSubscribed" (closeModal)="closeInviteFriendsModal()"></app-invite-friends>
</ng-template>
