import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { base64ToFile, ImageCroppedEvent } from "ngx-image-cropper";
import { PanelService } from "src/app/services/panel/panel.service";

@Component({
  selector: 'app-image-resizer',
  templateUrl: './image-resizer.component.html',
  styleUrls: ['./image-resizer.component.scss']
})
// ImageCropperComponet is already name in dependency so, name is ImageResizerComponent
export class ImageResizerComponent implements OnChanges {
  @Input('fetchedImg') fetchedImg: string;
  @Output('imageError') imageError = new EventEmitter<any>();
  @Output('finalImage') finalImage = new EventEmitter<any>();

  originalImageData: any;
  // Final image after being cropped
  cropperSettings: any;
  imageChangedEvent: any;
  lastModified: number;
  name: string;
  timestamp = new Date().getTime();
  hasErrorMessage: boolean;

  constructor(
    public panelService: PanelService,
  ) { }

  resizeCroppedImage(imageData) {
    const img = new Image();
    img.src = imageData;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      // Set canvas dimensions to desired width and height
      canvas.width = 800;
      canvas.height = 420;
      // Draw the cropped image onto the canvas
      ctx.drawImage(img, 0, 0, 800, 420);
      // Get the base64 representation of the canvas
      imageData = canvas.toDataURL('image/jpeg', 0.7);
      //Emit cropped image to the parent component
      const imageFile = base64ToFile(imageData);
      imageFile['lastModified'] = this.lastModified ? this.lastModified : Date.now();
      imageFile['name'] = this.name ? this.name : `external-link-o-g-image-${this.timestamp}.jpeg`;
      this.finalImage.emit(imageFile);
      this.hasErrorMessage = false;
      this.imageError.emit({ message: ''});
    };
  }

  fileChangeEvent(event: any) {
    const file: File = event.target.files[0];
    this.lastModified = file.lastModified;
    this.name =  file.name.replace(/\s+/g, '-');
    let width: number;
    let height: number;
    if (file) {
      this.panelService.getImageDimensions(file).subscribe(
        dimensions => {
          width = dimensions.width;
          height = dimensions.height;
          const validImage = (file.type == "image/jpg" || file.type == "image/jpeg" || file.type == "image/png") && (
              (width >= 800 && height >= 420));
          if (validImage) {
            this.imageChangedEvent = event;
            this.hasErrorMessage = false;
            this.fetchedImg = '';
            this.imageError.emit({ message: '', fileName: event.target.files[0].name});

          } else {
            this.imageChangedEvent = null;
            this.hasErrorMessage = true;
            this.imageError.emit({ message: 'Preview image too small. Please provide your own image.', fileName: event.target.files[0].name});
          }
        },
        error => {
          console.log('Error:', error);
        }
      );
    }

  }

  croppedImage(event: ImageCroppedEvent): void {
    const croppedFile = base64ToFile(event.base64);
    if (croppedFile) {
      this.resizeCroppedImage(event.base64);
    } else {
      this.fetchedImg === 'no-image';
    }
  }

  ngOnChanges() {
    if(this.fetchedImg === 'no-image') {
      this.imageChangedEvent = null;
      this.hasErrorMessage = true;
      this.imageError.emit({ message: 'Preview image not found. Please provide your own image.'});
    }
  }
}
