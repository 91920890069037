export interface MonthlyData {
  monthlyPageViews: number;
  revenuePerPage: number;
}
export interface DailyData {
  createdAt?: number;
  webPageviews: number;
  iOSPageviews: number;
  androidPageviews: number;
  unknownPageviews: number;
  pageviews: number;
  seriesPageViews: SeriesPageViewsData;
  totalGifts: number;
  totalGiftsAmount: number;
  seriesGifts: SeriesGifts;
  lastUpdated: number;
  monthly?: string;
  date?: string;
};

export interface SeriesGifts {
  lastUpdated: number;
  series: Series
};

interface Pageviews {
  displayValue: string;
  total: number;
  value: number;
}

interface SeriesPageViewsViewData {
  web: Pageviews;
  ios: Pageviews;
  android: Pageviews;
  unknown: Pageviews;
  total: Pageviews;
}

export interface SeriesPageViewsData {
  lastUpdated: number;
  series: SeriesPageViews;
};

export interface SeriesPageViews {
  [seriesName: string]: SingleSeriesPageViewsData;
};

export interface SingleSeriesPageViewsData {
  webPageviews: number;
  iOSPageviews: number;
  androidPageviews: number;
  unknownPageviews: number;
  pageviews: number;
}

export interface Date {
  displayValue: string;
  timestamp: number;
}

export interface Series {
  [key: string]: {
    totalGifts: number;
    totalGiftsAmount: number;
    gifts: GiftData
  };
};

export interface GiftData {
  [key: string]: Gift;
};

export interface Gift {
  amount: number;
  count: number;
  reactionName: string;
};

export interface DefaultViewData {
  displayValue: string;
  total: number;
  value: number;
}

export interface ReferralData {
  displayValue: string,
  value: number
}

export interface SeriesGiftsViewData extends DefaultViewData {
  detailedGiftVD: Record<string, TransformedGift>;
  date: Date;
  totalGiftVD: TransformedGift;
}

export interface TransformedGift {
  displayValue: string;
  quantity: number;
  seriesRevenue: string;
  totalSeriesRevenue: number;
}

export interface FinalVD {
  [key: string]: {
    date: Date;
    seriesPageViews: SeriesPageViewsViewData;
    seriesPercentage: DefaultViewData;
    pageViewsRevenue: DefaultViewData;
    virtualGifts: SeriesGiftsViewData;
    seriesRevenue: DefaultViewData;
  };
}


export class DailyDataCalculation {
  public date: Date;
  public seriesPageViews: SeriesPageViewsViewData;
  public seriesPercentage: DefaultViewData;
  public pageViewsRevenue: DefaultViewData;
  public virtualGifts: SeriesGiftsViewData;
  public seriesRevenue: DefaultViewData;
  public monthly: string;
  public revenuePerPage: number;

  constructor(dailyData: DailyData, series: string, monthlyData: MonthlyData) {
    this.revenuePerPage = monthlyData.revenuePerPage;
    this.monthly = dailyData.monthly;
    this.date = dailyData.date && this.formatDate(parseInt(dailyData.date));
    this.seriesPageViews = this.calculateSeriesPageViews(dailyData.seriesPageViews && dailyData.seriesPageViews.series && dailyData.seriesPageViews.series[series]);
    this.seriesPercentage = this.calculateSeriesPercentage(monthlyData.monthlyPageViews, dailyData.seriesPageViews.series[series]);
    this.pageViewsRevenue = this.calculatePageViewsRevenue(dailyData.seriesPageViews.series[series].pageviews);
    this.virtualGifts = this.calculateVirtualGifts(dailyData.seriesGifts.series[series].totalGiftsAmount, dailyData.seriesGifts.series[series].gifts, this.date.timestamp);
    this.seriesRevenue = this.calculateSeriesRevenue(this.pageViewsRevenue.value, this.virtualGifts.value);
  }

  private calculateSeriesPageViews(data: SingleSeriesPageViewsData): SeriesPageViewsViewData {
    if (!data) {
      return {
        web: {
          displayValue: '0%',
          total: 0,
          value: 0
        },
        ios: {
          displayValue: '0%',
          total: 0,
          value: 0
        },
        android: {
          displayValue: '0%',
          total: 0,
          value: 0
        },
        unknown: {
          displayValue: '0%',
          total: 0,
          value: 0
        },
        total: {
          displayValue: '0',
          total: 0,
          value: 0
        }
      }
    }
    const total = this.handleUndefinedValues(data.webPageviews) + this.handleUndefinedValues(data.iOSPageviews) + this.handleUndefinedValues(data.androidPageviews);
    const [web, webValue] = this.calculatePercentage(data.webPageviews, total);
    const [ios, iosValue] = this.calculatePercentage(data.iOSPageviews, total);
    const [android, androidValue] = this.calculatePercentage(data.androidPageviews, total);
    const [unknown, unknownValue] = this.calculatePercentage(data.unknownPageviews, total);

    return {
      web: {
        displayValue: web,
        total: data.webPageviews,
        value: webValue
      },
      ios: {
        displayValue: ios,
        total: data.iOSPageviews,
        value: iosValue
      },
      android: {
        displayValue: android,
        total: data.androidPageviews,
        value: androidValue
      },
      unknown: {
        displayValue: unknown,
        total: data.unknownPageviews,
        value: unknownValue
      },
      total: {
        displayValue: DailyDataCalculation.formatNumberToUSFormat(total, false),
        total: total,
        value: total
      }
    };
  }

  static formatNumberToUSFormat(number: number, includeDecimals: boolean = true, decimalPlaces: number = 2): string {
    // Ensure the input is a valid number
    if (isNaN(number)) {
      number = 0;
      // return 'Invalid Number';
    }

    if (number == 0) {
      includeDecimals = false;
    }

    // Use toLocaleString with options to format as US currency
    return number.toLocaleString('en-US', {
      minimumFractionDigits: includeDecimals ? decimalPlaces : 0,
      maximumFractionDigits: includeDecimals ? decimalPlaces : 0
    });
  }

  private calculatePercentage(value: number, total: number): [string, number] {
    value = this.handleUndefinedValues(value);
    total = this.handleUndefinedValues(total);

    return [
      total !== 0 ? `${Math.round(((value / total) * 100))}%` : '0%',
      total !== 0 ? Math.round(((value / total) * 100)) : 0
    ];
  }

  private calculateSeriesPercentage(pageviews: number, seriesPageViews: SingleSeriesPageViewsData): DefaultViewData {
    let totalOfAllPageviews = 0;
    totalOfAllPageviews += this.handleUndefinedValues(seriesPageViews.webPageviews) +
      this.handleUndefinedValues(seriesPageViews.androidPageviews) +
      this.handleUndefinedValues(seriesPageViews.iOSPageviews);

    return {
      displayValue: totalOfAllPageviews !== 0 ? `${((totalOfAllPageviews / pageviews) * 100).toFixed(4)}%` : '0%',
      total: totalOfAllPageviews,
      value: totalOfAllPageviews !== 0 ? parseFloat(((totalOfAllPageviews / pageviews) * 100).toFixed(4)) : 0
    };
  }

  private calculatePageViewsRevenue(pageviews: number): DefaultViewData {
    pageviews = this.handleUndefinedValues(pageviews);

    const revenuePerPageview = this.handleUndefinedValues(this.revenuePerPage);
    const pageViewsRevenue = (pageviews * revenuePerPageview);

    return {
      displayValue: `$${DailyDataCalculation.formatNumberToUSFormat(pageViewsRevenue)}`,
      total: pageviews,
      value: pageViewsRevenue
    };
  }

  private formatGiftName(gift: string): string {
    return gift.toLowerCase()
      .split("_")
      .map((a) => a[0] && a[0].toUpperCase() + a.slice(1))
      .join(" ")
      .trim();
  }

  private calculateVirtualGifts(amount: number, gifts: GiftData, giftLastUpdated: number): SeriesGiftsViewData {
    amount = this.handleUndefinedValues(amount);

    const [detailedGiftVD, totalGifts] = this.transformGiftData(gifts);
    return {
      displayValue: `$${DailyDataCalculation.formatNumberToUSFormat(amount || 0, true, 4)}`,
      total: this.handleUndefinedValues(amount),
      value: this.handleUndefinedValues(amount),
      detailedGiftVD: detailedGiftVD,
      date: this.formatGiftDate(giftLastUpdated),
      totalGiftVD: totalGifts
    };
  }

  private handleUndefinedValues(value: any) {
    return typeof value === 'number' ? value : 0;
  }

  private formatGiftDate(timestamp: number): Date {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };

    const formattedDate = new Date(timestamp).toLocaleDateString('en-US', options);
    return {
      timestamp,
      displayValue: `Received on ${formattedDate}`
    };
  }

  private transformGiftData(gifts: GiftData): [Record<string, TransformedGift>, TransformedGift] {
    const transformedGifts: Record<string, TransformedGift> = {};

    const totalGifts = {
      displayValue: 'Total',
      quantity: 0,
      seriesRevenue: '',
      totalSeriesRevenue: 0
    };

    for (const key in gifts) {

      if (gifts.hasOwnProperty(key)) {
        const gift = gifts[key];
        const existingGift = transformedGifts[gift.reactionName];

        if (existingGift) {
          // If the gift already exists, sum quantity and seriesRevenue
          existingGift.quantity += this.handleUndefinedValues(gift.count);
          existingGift.totalSeriesRevenue += this.handleUndefinedValues(gift.amount);
          totalGifts.quantity += this.handleUndefinedValues(gift.count);
          totalGifts.totalSeriesRevenue += this.handleUndefinedValues(gift.amount);
        } else {
          // If the gift doesn't exist, create a new entry
          const transformedGift: TransformedGift = {
            displayValue: this.formatGiftName(gift.reactionName),
            quantity: this.handleUndefinedValues(gift.count),
            totalSeriesRevenue: this.handleUndefinedValues(gift.amount),
            seriesRevenue: ''
          };
          totalGifts.quantity += this.handleUndefinedValues(gift.count);
          totalGifts.totalSeriesRevenue += this.handleUndefinedValues(gift.amount);
          transformedGifts[gift.reactionName] = transformedGift;
        }
      }
    }

    // Format seriesRevenue to $xx.xx
    Object.values(transformedGifts).forEach((gifts) => {
      gifts.seriesRevenue = `$${gifts.totalSeriesRevenue.toFixed(4)}`;
    });

    totalGifts.seriesRevenue = `$${totalGifts.totalSeriesRevenue.toFixed(4)}`;

    return [transformedGifts, totalGifts];
  }

  // remaining to correct
  private calculateSeriesRevenue(virtualGiftRevenue: number, pageViewsRevenue: number): DefaultViewData {
    const totalRevenue = this.handleUndefinedValues(virtualGiftRevenue) + this.handleUndefinedValues(pageViewsRevenue);
    return {
      displayValue: `$${DailyDataCalculation.formatNumberToUSFormat(totalRevenue)}`,
      total: totalRevenue,
      value: totalRevenue
    };
  }

  private formatDate(timestamp: number): Date {
    const date = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = { weekday: 'short', month: 'short', day: 'numeric' };
    return {
      displayValue: date.toLocaleDateString('en-US', options),
      timestamp: timestamp
    };
  }
}

export class TopReferrerAdaptor {
  public referrerName: string;
  public referralAmt : ReferralData;
  public referralCount: ReferralData;
  constructor(data) {
    this.referrerName = data.displayName || 'Guest User';
    this.referralAmt = this.getReferralAmt(parseFloat(data.totalReferralAmount));
    this.referralCount = this.getReferralCount(parseInt(data.totalReferral));
  }

  private getReferralAmt(referralAmt: number) {
    return {
      displayValue : `$${DailyDataCalculation.formatNumberToUSFormat(referralAmt, true, 4)}`,
      value: referralAmt
    }
  }

  private getReferralCount(referralCount: number) {
    if (isNaN(referralCount)) {
      referralCount = 0;
      // return 'Invalid Number';
    }
    return {
      displayValue : `${referralCount}`,
      value: referralCount
    }
  }
}

export class DailyReferralAdaptor {
  public date: Date;
  public referralCount: ReferralData;
  public referralAmt: ReferralData;

  constructor(dailyData) {
    this.date = dailyData.createdAt && this.formatDate(parseInt(dailyData.createdAt));
    this.referralCount = this.getReferralCount(parseInt(dailyData.totalReferral));
    this.referralAmt = this.getReferralAmt(parseFloat(dailyData.totalReferralAmount));
  }
  private formatDate(timestamp: number): Date {
    const date = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = { weekday: 'short', month: 'short', day: 'numeric' };
    return {
      displayValue: date.toLocaleDateString('en-US', options),
      timestamp: timestamp
    };
  }

  private getReferralCount(referralCount: number) {
    return {
      displayValue: String(referralCount),
      value: referralCount
    }
  }

  private getReferralAmt(referralAmt) {
    return {
      displayValue: `$${DailyDataCalculation.formatNumberToUSFormat(referralAmt, true, 4)}`,
      value: referralAmt
    }
  }
} 
