import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { subscribeURL, templateTypes } from '../constants/common.constants';
import { ComicService } from '../services/comic.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalConfig } from '../interfaces/user.interface';
import { ModalService } from '../services/modal.service';
import * as util from '../utilities/common.util';
import { LocalStorageService } from '../services/local-storage.service';
const COMIC_IMG_API = `${environment.API_END_POINT}`;

@Component({
  selector: 'app-comic-bonus',
  templateUrl: './comic-bonus.component.html',
  styleUrls: ['./comic-bonus.component.scss']
})
export class ComicBonusComponent implements OnInit {
  @Input() templateType?: string;

  currentUrl: string = '';
  bonusPanelImage: string;
  subscribeURL = subscribeURL;
  unlockBonusPanelRef: BsModalRef;
  currentDevice: string;
  isComicUnlocking: boolean;
  influencePointsBalance: number;
  anonymousUser: boolean;
  public actionSheetConfig: ModalConfig = {
    header: '',
    subHeader: '',
    buttons: []
  };
  isSubscriber: boolean;

  constructor(
    private comicService: ComicService,
    private modalService: ModalService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    this.currentUrl = this.comicService.getCurrentUrl();
    this.influencePointsBalance = (this.comicService.influencePoints && this.comicService.influencePoints.balance) || 0;
    this.anonymousUser = util.isAnonymousUser();
    this.isSubscriber = this.localStorageService.getItem('productID') && this.localStorageService.getItem('productID').length != 0;
    this.setDefaultActionSheetConfig();
  }

  public setDefaultActionSheetConfig() {
    let path = COMIC_IMG_API;
    // Unlock Bonus Panel config as default
    this.actionSheetConfig.header = 'Unlock Bonus Panel';
    this.actionSheetConfig.subHeader = `You have ${this.influencePointsBalance} influence point${(this.influencePointsBalance !== 1) ? 's' : ''}.`;
    this.bonusPanelImage = path + '/tinyview/app/bonus-panel-with-button.jpg';
    this.actionSheetConfig.buttons.push({
      text: 'Subscribe',
      action: 'subscribe'
    });
    this.actionSheetConfig.buttons.push({
      text: 'Use 1 Influence Point',
      action: 'useInfluencePoints',
      disabled: this.influencePointsBalance < 1
    });
    this.actionSheetConfig.buttons.push({
      text: 'Earn Influence Points',
      action: 'earnInfluencePoints'
    });
    // For anonymous User only
    if (this.anonymousUser && !this.isSubscriber) {
        this.actionSheetConfig.buttons.push({
        text: 'I am a Subscriber',
        action: 'redirectToSignIn'
      });
    }
    this.actionSheetConfig.buttons.push({
      text: 'Not Now',
      action: 'closeModal',
      // class: 'cancel'
    });

    this.actionSheetConfig.modalData = {
      influencePointsBalance: this.influencePointsBalance
    };

    // Unlock Premium Comic config
    if (this.templateType === templateTypes.premium) {
      this.actionSheetConfig.header = 'Unlock Premium Comic';
      this.bonusPanelImage = path + '/tinyview/app/tinyview-premium-comic-with-button.jpg';
    }

    // Unlock Unlimited Comic config
    if (this.templateType === templateTypes.comicLimit) {
      this.actionSheetConfig.header = 'Get Unlimited Access';
      this.actionSheetConfig.subHeader = `Sign Up or Sign In using email or phone for unlimited access. It's free. Also, earn 5 influence points when you Sign Up and use them to unlock bonus panels.`;
      this.actionSheetConfig.buttons = [];
      this.actionSheetConfig.buttons.push({
        text: 'Sign Up',
        action: 'redirectToSignUp'
      });
      this.actionSheetConfig.buttons.push({
        text: 'Sign In',
        action: 'redirectToSignIn'
      });
      this.actionSheetConfig.buttons.push({
        text: 'Not Now',
        action: 'closeModal',
        class: 'cancel'
      });

      this.bonusPanelImage = path + '/tinyview/app/tinyview-free-comics-limit-with-button.jpg';
    }
  }

  public showActionSheet() {
    console.log('Triggering showActionSheet with config:', this.actionSheetConfig); // Debug statement
    this.modalService.showActionSheetModal(this.actionSheetConfig);
  }
}
