<div class="tv-card p-0 pb-2">
  <div class="row" *ngIf="isUserLikesLoading">
    <div class="placeholder-image">
      <img src="../../assets/images/content-placeholder.gif" />
    </div>
  </div>
  <div *ngIf="!isUserLikesLoading">
    <div *ngIf="comicData?.image && !isUpload">
      <div *ngIf="comicData?.image && !isUpload">
        <div class="row">
          <div class="col-5 col-md-4">
            <a *ngIf="!isWebsite()" (click)="getImageAction()">
              <img [src]="getImgUrl(comicData)" [class.rounded-image]="isRounded" class="link image" alt="panel image">
            </a>
            <a *ngIf="isWebsite()" [href]="comicAction" target="_blank" class="image">
              <img [src]="getImgUrl(comicData)" [class.rounded-image]="isRounded" alt="panel" class="image">
            </a>
          </div>
          <div class="col-7 col-md-8 pl-2" *ngIf="comicData && comicData?.action">
            <a>
              <h2 (click)="onImageClick()" *ngIf="comicData?.title"> {{ comicData.title }}</h2>
            </a>
                <div class="my-2 pt-1">
                  <p>
                    <span class="p-bold">{{comicData.viewCount | numberFormat}}</span>
                    <ng-container *ngIf="comicData.viewCount > 1 || comicData.viewCount == 0">
                      {{viewText}}
                    </ng-container>
                    <ng-container *ngIf="comicData.viewCount == 1">
                      {{viewText}}
                    </ng-container>
                  </p>
                  <p *ngIf="comicData.viewCount == null"><span class="p-bold">0</span>
                    Views
                  </p>
                  <p class="mt-2">
                    <span class="p-bold">{{comicData.likeCount | numberFormat}}</span>
                    <ng-container *ngIf="comicData.likeCount > 1 || comicData.likeCount == 0">
                      {{likeText}}
                    </ng-container>
                    <ng-container *ngIf="comicData.likeCount == 1">
                      Follower
                    </ng-container>
                  </p>
                  <p *ngIf="comicData.likeCount == null"><b>0</b>
                    Followers
                  </p>
                </div>
                <a>
                    <div class="loader-bar-btn mx-1" *ngIf="showLoader"></div>
                    <p class="d-inline-flex" *ngIf="!showLoader" (click)="getAddRemoveLikeData(comicData)">
                      <img class="icon-20 mr-2 my-auto"
                        src="{{ isFollow ? '../../assets/images/new-icons/tick-box-100.png' : '../../assets/images/new-icons/icons8-add-100.png' }}">
                      {{followText}}
                    </p>
                  <!-- </span> -->
                </a>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="comicData && comicData?.action">
      <p class="default-padding px-4 pb-3" *ngIf="comicData?.description" [innerHTML]="comicData.description"></p>
    </ng-container>
    <div *ngIf="comicData?.image && uploadURL" [ngStyle]="getImageBorder()">
      <div class="image-container">
        <img [src]="uploadURL" alt="panel image">
      </div>
    </div>
    <app-comic-buttons [comicData]="comicData"></app-comic-buttons>
  </div>
</div>