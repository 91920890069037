import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { AUTH_TYPE, LOGIN_FLOW, signInPhoneURL, signUpPhoneURL } from "src/app/constants/common.constants";
import { subscriptionAnonymousMsg } from "src/app/constants/subscription.constants";
import { NewComicService } from "src/app/new-comic.service";
import { AuthService } from "src/app/services/auth.service";
import { ComicService } from "src/app/services/comic.service";
import { LoginService } from "src/app/services/login.service";
import { isAnonymousUser } from "src/app/utilities/common.util";

@Component({
  selector: 'app-email-login',
  templateUrl: './email-login.component.html',
  styleUrls: ['./email-login.component.scss', '../../signin/signin.component.scss']
})

export class EmailLoginComponent {
  public emailForm: FormGroup;
  public fetchDone: boolean = true;
  public emailTouched: boolean;
  public isEmailSent: boolean;
  public sendEmailLinkError: string;
  errorEncountered: boolean;
  public currentFlow: string = LOGIN_FLOW.SIGNIN;
  userExistsWithEmail: boolean;
  userNotExistsWithEmail: boolean;
  isMobile: boolean;
  isAnonymousUser: boolean;
  tempError: boolean;
  redirectionType: string;
  message: string;
  comicSeries: any;
  emailSubscription: Subscription;
  redirectionString: string;
  series: any;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private readonly comicService: ComicService,
    private router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private newComicService: NewComicService,
    private loginService: LoginService
  ) {
    this.createEmailForm();
    this.initializeCurrentFlow();
    this.isMobile = this.newComicService.isMobileView();
    this.isAnonymousUser = isAnonymousUser();
  }

  public initializeCurrentFlow() {
    const url = this.comicService.getCurrentUrl();
    this.currentFlow = this.loginService.getCurrentFlow(url);

    this.message = this.activatedRoute.snapshot.queryParamMap.get('message');
    if (this.message) this.redirectionType = 'friend_request';

    this.redirectionString = this.activatedRoute.snapshot.queryParamMap.get('showSubscriptionMsg') || '';


    const emailID = this.activatedRoute.snapshot.queryParamMap.get('email');
    if (emailID) this.emailForm.patchValue({ email: emailID });

    const series = this.activatedRoute.snapshot.queryParamMap.get('series') || '';
    this.series = series && JSON.parse(series);

    // For email Login Unsuccessfull
    const emailVerificationState = this.activatedRoute.snapshot.queryParamMap.get('emailVerificationState');
    if (emailVerificationState === 'fail') return this.errorEncountered = true;

    // For succesfull email-sent screen
    const otpPattern = /\/email-sent$/;
    if (otpPattern.test(url)) {
      this. isEmailSent = true;
      this.errorEncountered = false;
    }
  }

  private createEmailForm(emailDefault = '') {
    this.emailForm = this.fb.group({
      email: [emailDefault, [Validators.required, this.emailValidator]]
    });
  }

  onBlur() {
    this.emailTouched = true;
  }

  onInput() {
    this.emailTouched = false;
    this.userExistsWithEmail = false;
    this.userNotExistsWithEmail = false;
    this.tempError = false;
    this.sendEmailLinkError = '';
  }

  showEmailError() {
    return this.emailTouched && this.emailForm.get('email').invalid;
  }

  emailValidator(control) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (pattern.test(control.value)) {
      return null; // Validation passed
    } else {
      return { invalidEmail: true }; // Validation failed
    }
  }

  public async sendEmail(emailID: string, isResend = false) {
    this.fetchDone = false;
    const email: string = emailID.toLowerCase();
    try {
      const userExists = await this.authService.checkForExistingUser(AUTH_TYPE.EMAIL, email);
      if ((this.currentFlow === LOGIN_FLOW.SIGNIN && userExists) || ([LOGIN_FLOW.SIGNUP].includes(this.currentFlow) && !userExists) || ((this.currentFlow.toLowerCase() === LOGIN_FLOW.ALERTS.toLowerCase()) && (this.isAnonymousUser || !userExists))) {
        // If it is SIGNIN flow and user does exists
        // or If it is SIGNUP flow and user does NOT exists
        // or If it is ALERTS flow and (user does NOT exists or is anonymous)
        await this.authService.sendEmailLink(email, this.currentFlow, this.redirectionType, this.series);
        this.isEmailSent = true;
        this.errorEncountered = false;
        this.userNotExistsWithEmail = false;
        this.userExistsWithEmail = false;
        this.tempError = false;
        this.message = '';
        // Navigate to email-sent
        let route = 'email-sent';
        // if ([LOGIN_FLOW.SIGNUP].includes(this.currentFlow)) route = 'signup/email-sent';
        // this.router.navigate([route], { queryParams: { email: emailID } });
        const queryParams = { email: emailID };
        if (this.series) queryParams['series'] = JSON.stringify(this.series);
        this.loginService.navigateTo(route, this.currentFlow, queryParams);
      } else if (userExists && [LOGIN_FLOW.SIGNUP].includes(this.currentFlow)) {
        // If it is SIGNUP flow and user does exists
        this.userExistsWithEmail = true;
        this.userNotExistsWithEmail = false;
        this.errorEncountered = false;
        this.tempError = false;
        this.isEmailSent = false;
      } else if (!userExists && this.currentFlow === LOGIN_FLOW.SIGNIN) {
        // If it is SIGNIN flow and user does NOT exists
        this.userNotExistsWithEmail = true;
        this.userExistsWithEmail = false;
        this.errorEncountered = false;
        this.tempError = false;
        this.isEmailSent = false;
      } else if ((this.currentFlow.toLowerCase() === LOGIN_FLOW.ALERTS.toLowerCase()) && !this.isAnonymousUser && userExists) { // we need to merge in this case
        // If it is ALERTS flow and user does exists and is NOT anonymous
        await this.authService.sendEmailLink(email, this.currentFlow, this.redirectionType, this.series);
        this.isEmailSent = true;
        this.errorEncountered = false;
        this.userNotExistsWithEmail = false;
        this.userExistsWithEmail = false;
        this.tempError = false;
        this.message = '';
      }

      if (isResend) this.toastr.success('Verification link has been sent.');
      this.fetchDone = true;

    } catch (error) {
      console.log(error.message);
      if (isResend) this.toastr.error(error.message);
      this.sendEmailLinkError = error.message
      this.isEmailSent = false;
      this.fetchDone = true;
    }
  }

  public onSubmit() {
    this.fetchDone = false;
    if (this.emailForm.valid) {
      this.sendEmail(this.emailForm.value.email.trim());
    } else {
      // Mark the field as touched to display validation error
      this.emailForm.markAllAsTouched();
      this.emailTouched = true;
    }
  }

  navigateTo(page?) {
    this.comicSeries = this.activatedRoute.snapshot.queryParams.comic;
    const queryParams = {};

    if (this.emailForm.value.email.trim())  queryParams['email'] = this.emailForm.value.email.trim();
    if (this.comicSeries) {
      queryParams['comic'] = this.comicSeries;
      queryParams['message'] = this.message
    }
    if (this.activatedRoute.snapshot.queryParams.showSubscriptionMsg) {
      queryParams['showSubscriptionMsg'] = subscriptionAnonymousMsg;
    }
    if (page) { 
      if (page === 'signin') return this.loginService.navigateTo('email', LOGIN_FLOW.SIGNIN, queryParams );
      if (page === 'signup') return this.loginService.navigateTo('dob' , LOGIN_FLOW.SIGNUP, queryParams );
    } else {
      this.loginService.navigateTo('phone/number', this.currentFlow, queryParams)
    }
  }

  hasAnyError() {
    if (this.userExistsWithEmail) return true;
    if (this.userNotExistsWithEmail) return true;
    if (this.tempError) return true;
    if (this.showEmailError()) return true;
    if (this.sendEmailLinkError) return true;
    return false;
  }
}