import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

export const SERIES_LIST_DATA = 'SERIES_LIST_DATA';
export const SERIES_DATA = 'SERIES_DATA';
export const STORY_DATA = 'STORY_DATA';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  constructor(
    private localStorageService: LocalStorageService
  ) { }


  private getEncodedKey(keyName: string): string {
    return btoa(keyName);
  }

  // Get data from cache
  public get(keyN: string): any {
    const key = this.getEncodedKey(keyN);
    const data = this.localStorageService.getItem(key);
    if (!data) {
      return null;
    }

    const now = new Date().getTime();
    if (!data.expiry || now > data.expiry) {
      this.localStorageService.removeItem(key);
      return null;
    }

    return data.value;
  }

  /**
   * Caches the provided directory data.
   * @param [key]
   * @param [value]
   * @param [ttl=180000] - 3 minutes
   */
  // Set data to cache
  public set(keyN: string, value: any, ttl: number = 180000): void {
    const key = this.getEncodedKey(keyN);
    const expiry = new Date().getTime() + ttl;
    this.localStorageService.setItem(key, { expiry, value });
  }

  // Cache and return the Observable
  public cacheObservable(key: string, fallback: Observable<any>, ttl?: number): Observable<any> {
    const cached = this.get(key);
    if (cached) {
      return cached;
    } else {
      return fallback.pipe(
        tap(value => {
          this.set(key, value, ttl);
        })
      );
    }
  }
}
