import { Component, Inject, Input, PLATFORM_ID } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { isPlatformBrowser } from '@angular/common';
import { CacheService } from "src/app/services/cache.service";


@Component({
  selector: 'app-follow-modal',
  templateUrl: './follow-modal.component.html',
  styleUrls: ['./follow-modal.component.scss']
})
export class FollowModalComponent {
  @Input() tinyviewPage: boolean;
  @Input() isFollowingAlready: boolean;
  @Input() series: any;
  constructor(
    public modalRef: BsModalRef,
    private readonly router: Router,
    @Inject(PLATFORM_ID) private platform: object,
    private cacheService: CacheService
  ) {}

  closeModal(isFollowingAlreadyModal) {
    if (isFollowingAlreadyModal && isPlatformBrowser(this.platform)) {
      this.cacheService.set('hideFollowingAlreadyModal', true, 86400000);
    }
    this.modalRef.hide();
  }

  onManageAlertClick(): void {
    if (this.isFollowingAlready && isPlatformBrowser(this.platform)) {
      this.cacheService.set('hideFollowingAlreadyModal', true, 86400000);
    }
    if (this.modalRef) this.modalRef.hide();
    this.router.navigate(['manage-alerts'], { queryParams: { series: JSON.stringify(this.series) }});
  }
 }