import { Component, Input, OnInit } from '@angular/core';
import * as firebase from 'firebase/app';
import { ComicService } from '../services/comic.service';
import { StoryFeedService } from '../services/story-feed.service';

@Component({
  selector: 'app-gift-items',
  templateUrl: './gift-items.component.html',
  styleUrls: ['./gift-items.component.scss']
})
export class GiftItemsComponent implements OnInit {
  public giftsList: any = [];
  @Input() giftModalRef;
  @Input() storyID: string;
  @Input() giftItemCount: any;
  @Input() showUserNamesRef: any;
  @Input() commentId: string;
  @Input() subCommentId: string;
  public showLoader: boolean;
  UID: string;
  click: boolean = false;
  userCommentDetails: any = [];
  productData = [];
  selectedProduct: any;

  onScroll() {
    let startAfter = this.giftsList[this.giftsList.length - 1] ? this.giftsList[this.giftsList.length - 1].likeID : '';
    let reactionType = this.giftsList[this.giftsList.length - 1].reaction
    this.feedService.getStoryLikes(this.storyID, startAfter, reactionType).then(async (res) => {
      await res.data.data.likesList.map(i => {
        this.giftsList.push(i);
      })
    })
  }

  constructor(public feedService: StoryFeedService, public comicService: ComicService) {
    const user = firebase.auth().currentUser;
    if (user) {
      this.UID = user.uid
    }
  }

  ngOnInit() {
    this.getLikedCommentUser();

    !this.commentId && this.comicService.giftItems.data.giftItems.map(item => {
      const img = item.reactionName === 'LIKE' ? '../../assets/images/red-heart-100.png' : item.image;
      this.productData.unshift({
        reactionName: item.reactionName,
        imageSrc: img,
        count: this.giftItemCount[item.reactionName]
      });
    });

    this.selectedProduct = this.productData.length && this.productData.find(obj => !!obj.count).reactionName || 'LIKE';
    this.getDataFromTabs(this.selectedProduct, false);
  }

  public getLikedCommentUser() {
    if (this.commentId || this.subCommentId) {
      if (this.showLoader) return;

      this.showLoader = true;
      this.feedService.userLikedComments(this.commentId, this.subCommentId).then((res) => {
        this.userCommentDetails = res.data.data;
        this.showLoader = false;
      }, () => {
        this.showLoader = false;
      });
    }
  }

  getDataFromTabs(reactionType: string, isCheck: boolean) {
    if (this.showLoader) return;

    this.showLoader = true;
    this.selectedProduct = reactionType;
    this.giftsList = [];
    this.feedService.getStoryLikes(this.storyID, '', reactionType).then((res) => {
      res.data.data.likesList.map(i => {
        this.showLoader = false;
        this.giftsList.push(i);
      });
    }, () => {
      this.showLoader = false;
    });
  }

  public getFirstLetters(str) {
    if (str) {
      const firstLetters = this.comicService.getFirstLetters(str);
      return firstLetters;
    } else {
      return 'GU';
    }
  }

  public showTime(time) {
    return this.comicService.getDataDiff(time);
  }

  public getBadgeURL(badgeName: string) {
    return this.comicService.mapUserBadge(badgeName, 50);
  }

  public generateSrcset(srcset: any): string {
    return Object.entries(srcset).map(([size, src]) => `${src} ${size}`).join(', ');
  }
}
