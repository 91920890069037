import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardStatsComponent } from '../components/dashboard-stats/dashboard-stats.component';
import { CreatorsDashboardComponent } from './creators-dashboard.component';
import { ReferralDashboardComponent } from '../components/referral-dashboard/referral-dashboard.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { TopReferrerDashboardComponent } from '../components/top-referrer-dashboard/top-referrer-dashboard.component';

const routes: Routes = [
  {
    path: '', component: CreatorsDashboardComponent, children: [
      { path: '', component: DashboardComponent },
      { path: 'creators', component: DashboardStatsComponent },
      { path: 'referrals', component: ReferralDashboardComponent },
      { path: 'top-referrer', component: TopReferrerDashboardComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CreatorsDashboardRoutingModule { }