import { Component, ContentChild, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewComicService } from 'src/app/new-comic.service';
import { ComicService } from 'src/app/services/comic.service';
import { Location, isPlatformBrowser, DOCUMENT } from '@angular/common';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { isAnonymousUser } from 'src/app/utilities/common.util';
import { AuthService } from 'src/app/services/auth.service';
import { PageMetaService, DOCUMENT_TYPE } from "../../services/page-meta.service";
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-comic-wrapper',
  templateUrl: './comic-wrapper.component.html',
  styleUrls: ['./comic-wrapper.component.scss'],
})

export class ComicWrapperComponent implements OnInit {
  isMobile: boolean;
  isInfluencePointsPage: boolean;
  isTnCPage: boolean;
  isPrivacyPolicyPage: boolean;
  isNotificationPage: boolean;
  isSeriesPage: boolean;
  isComicPage: boolean;
  storyPage: boolean;
  @ViewChild('mainContent', { static: true }) mainContent!: ElementRef;
  @ViewChild('notificationContent', { static: false }) notificationContent!: ElementRef;
  private hideScrollbarTimeout: any;

  public isSignUpPage: boolean;
  public isSignInPage: boolean;
  isSignUpNewPage: boolean;
  currentScrolledPosition: any;
  crossIconSRC = '../../../assets/images/new-icons/icons8-close-100.png';
  backIconSRC = '../../../assets/images/new-icons/icons8-back-arrow-100.png';
  navIconImg: string;

  constructor(
    public readonly newComicService: NewComicService,
    private readonly router: Router,
    private readonly comicService: ComicService,
    private location: Location,
    private readonly localStorageService: LocalStorageService,
    private readonly authService: AuthService,
    @Inject(PLATFORM_ID) private platform: object,
    private readonly route: ActivatedRoute,
    private metaService: Meta,
    private titleService: Title,
    @Inject(DOCUMENT) private documentService: DOCUMENT_TYPE,
    private pageMetaService: PageMetaService,
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      // update tags on in app routing
      this.pageMetaService.setMetaTags(this.documentService, this.titleService, this.metaService)

      this.comicService.getGifts().then().catch();

    this.authService.isAnonymousUser();

    let series = [];

    this.isComicPage = this.comicService.isComicPage();
    this.storyPage = this.newComicService.isStoryPage();
    this.isInfluencePointsPage = this.newComicService.isInfluencePointsPage();
    this.isPrivacyPolicyPage = this.newComicService.isPrivacyPolicyPage();
    this.isNotificationPage = this.newComicService.isNotificationPage();
    // this.isSignUpPage = this.newComicService.isSignUpPage();
    this.isSignUpNewPage = this.newComicService.isSignUpNewPage();

    this.isSignInPage = this.newComicService.isSignInPage();
    this.isTnCPage = this.newComicService.isTnCPage();
    this.isMobile = this.newComicService.isMobileView();
    if (this.isMobile) this.setBackNavButton();
    this.comicService.getComicTitle().subscribe(res => {
      series = Object.keys(res).map(key => '/' + key);
      const pathName = window.location.pathname;
      if ([...series].includes(pathName)) {
        this.isSeriesPage = true;
      } else {
        this.isSeriesPage = false;
      }

      // Setting custom BG colors for each series
      const seriesName =
        this.newComicService.isHomePage() ||
          this.newComicService.isDirectoryPage() ||
          this.newComicService.isSubscribePage() ||
          this.newComicService.isInfluencePointsPage() ||
          this.newComicService.isTermsAndConditions() ||
          this.newComicService.isPrivacyPolicy() ||
          this.newComicService.isSeeFriends() ||
          this.newComicService.isFriendRequest() ||
          this.isSignInPage ||
          this.isSignUpPage ||
          this.isSignUpNewPage
          ? "tinyview"
          : this.isComicPage ? pathName.split('/')[1] : pathName;
      const color =
        (seriesName &&
          res[seriesName.replace("/", "")] &&
          res[seriesName.replace("/", "")].style &&
          res[seriesName.replace("/", "")].style.backgroundColor) ||
        "#FFE29A";
      const sideBGColor =
        (seriesName &&
          res[seriesName.replace("/", "")] &&
          res[seriesName.replace("/", "")].style &&
          res[seriesName.replace("/", "")].style.notificationColor) ||
        `${color}3D`;

      const bgColor = (
        this.storyPage
        || (this.newComicService.isManageAlertsPage() && this.isMobile)
      )
        ? '#FFF'
        : color;
      document.documentElement.style.setProperty('--bg-color', bgColor);
      document.documentElement.style.setProperty(`--side-bg-color`, sideBGColor);
    });

    this.comicService.getUnlockedComicsURLs().then().catch();

    setTimeout(async () => {
      const inviteFriendsData = this.localStorageService.getItem('invite_friends_data') || {};
      if (['FRIEND_REQUEST', 'INVITE_FRIEND'].includes(inviteFriendsData.actionType)) {
        let res;
        // calling friends API
        try {
          res = await this.newComicService.sendFriendRequest(inviteFriendsData);
        } catch (err) {
          console.log(err);
        }

        const successMsg = `You are now friends with ${inviteFriendsData.senderName || 'your friend'}.`

        if (res && res.requestStatus === 2) {
          return this.router.navigate(['see-friends'], { queryParams: { message: `You are already friend with the ${inviteFriendsData.senderName || 'your friend'}.` } });
        } else if (isAnonymousUser()) {
          // this.localStorageService.setItem('redirection_after_login', { route: 'friend-request', message: successMsg });
          const msg = `You must sign in to accept friend request from ${inviteFriendsData.senderName || 'your friend'}.`
          return this.router.navigate(['signin/phone/number'], { queryParams: { comic: 'friend-request', message: msg } });
        } else {
          return this.router.navigate(['friend-request']);
        }
      }
    }, 2000);

    // code to handle same menu click for home and following page for smart scrolling purpose
    this.newComicService.getsidebarItemSelected().subscribe(val => {
      if (val.sameItemClicked) {
        this.processScrolling();
      }
    });
   }
  }

  setBackNavButton() {
    const currentURL = this.router.url;
    const signupRegex = /^\/signup/;
    const signinRegex = /^\/signin/;
    const emailRegex = /^\/email-/;
    const isSignup = signupRegex.test(currentURL);
    const isSignin = signinRegex.test(currentURL);
    const isEmail = emailRegex.test(currentURL);
    if (isSignup || isSignin || isEmail) {
      this.navIconImg = this.backIconSRC;
    } else {
      this.navIconImg = this.crossIconSRC;
    }
  }
  // Helper function to check if the user is scrolling
  public isScrolling(event, element) {
    const selector = element.classList.contains('main-content') ? '.main-content' : element.classList.contains('notification') ? '.notification' : null;
    const targetElement = selector === '.main-content' ? this.mainContent : selector === '.notification' ? this.notificationContent : null;
    if (!selector) return;

    const content = document.querySelector('.main-content') as HTMLElement;
    if (content.scrollTop !== undefined) {
      const classes = targetElement.nativeElement.classList;
      classes.add('show-scrollbar');
      classes.remove('hide-scrollbar');
      clearTimeout(this.hideScrollbarTimeout);
      this.hideScrollbarTimeout = setTimeout(() => {
        classes.add('hide-scrollbar');
        classes.remove('show-scrollbar');
      }, 1500);
    }

    // Using infinite scroll only on home page, following page and series page.
    // TODO(Shashank): only triggers on desktop due to overflow on main-container
    if (selector && selector === '.main-content') {
      this.comicService.setInfiniteScroll(event);
    }
    this.currentScrolledPosition = this.mainContent.nativeElement.scrollTop;
  }

  // code to handle same menu click for home and following page for smart scrolling purpose
  public processScrolling() {
    const scrollTop = this.isMobile ? (window.scrollY || document.documentElement.scrollTop || document.body.scrollTop) : (this.mainContent.nativeElement.scrollTop || this.currentScrolledPosition);
    if (scrollTop > 0) {
      // The mainContent is not scrolled up to the top so scrolling it to the top
      if (this.isMobile) {

        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      } else {
        this.mainContent.nativeElement.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
    } else {
      // The mainContent is at the top so reload the center column
      // const uniqueQueryParam = Date.now().toString(); // uniqueQueryParam will force the mainContent to reload
      // this.router.navigate([''], {
      //   relativeTo: this.route,
      //   queryParams: { reload: uniqueQueryParam },
      //   queryParamsHandling: 'merge'
      // });
      window.location.reload();
    }
  }

  private setViewHeightForMobile() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  public loadThisComponent(componentName: string) {
    switch (componentName) {
      case 'manage-influence':
        return this.isInfluencePointsPage;
      case 'comic':
        return (
          !this.isInfluencePointsPage &&
          !this.isTnCPage &&
          !this.isPrivacyPolicyPage &&
          !this.isNotificationPage
        );
      case 'notifications-web':
      case 'sidebar':
        return !this.isMobile;
      case 'bottom-nav-mobile':
        return this.isMobile;
      case 'terms-condition':
        return this.isTnCPage;
      case 'privacy-policy':
        return this.isPrivacyPolicyPage;
      case 'notifications-mobile':
        return this.isNotificationPage;
    }
  }

  public navigateTo(page: string) {
    if (page === 'home') {
      this.newComicService.setsidebarItemSelected('home');
      this.router.navigate(['']);
    }
  }

  public goBack(): void {
    // 3 for mobile and 2 for desktop
    if ((window.history.length <= 3) || this.route.snapshot.queryParams.showSubscriptionMsg) {
      // If history length is 3 or less, navigate to home page
      this.router.navigate(['/']);
    } else {
      // Otherwise, go back
      this.location.back();
    }
  }
}
