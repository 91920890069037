import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { ComicService } from '../services/comic.service';

@Component({
  selector: 'app-action-popup',
  templateUrl: './action-popup.component.html',
  styleUrls: ['./action-popup.component.scss']
})
export class ActionPopupComponent implements OnInit {
  currentDevice: string;
  willShowLoader: boolean;
  smsResponse: string;
  @Input() modalRef: any;
  @Input() likedComic: any;
  @Input() ctaList: Array<string>;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];
  myform: FormGroup;
  constructor(private modalService: BsModalService, private readonly router: Router, private readonly comicService: ComicService) {
    this.currentDevice = this.comicService.getOperatingSystem();
    if (!this.ctaList) {
      this.ctaList = [];
    }
  }

  ngOnInit() {
    this.myform = new FormGroup({
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(10)
      ])
    });
  }

  public openApp(): void {
    this.comicService.openInStore();
  }

  public goToSignInPage() {
    this.modalRef.hide()
    this.router.navigate(['/signin/phone/number']);
  }

  // Dupe of comic component
  public sendSMS(modal) {
    let user = firebase.auth().currentUser;
    const phone = user.phoneNumber ? user.phoneNumber : this.myform.value.phone;
    if (phone) {
      this.smsResponse = null;
      this.willShowLoader = true;
      let tags = this.comicService.isCoverPage(true);
      if (tags == '') {
        tags = 'tinyview';
      }
      const linkData = {
        campaign: 'website',
        feature: 'open-in-app',
        channel: 'tinyview',
        tags: [tags],
        data: this.comicService.getBranchLinkData()
      };
      const linkOptions = {
        make_new_link: false
      };
      const callback = (err: any, result: any) => {
        if (err) {
          this.willShowLoader = false;
          this.smsResponse = 'Sorry, an error occurred. Please try again later.';
        } else {
          this.willShowLoader = false;
          this.smsResponse = 'Link sent successfully.';
        }
      };
      let number = user.phoneNumber ? this.formatPhoneNumber(phone) : this.myform.value.phone.internationalNumber
      if (window['branch']) {
        window['branch'].sendSMS(number, linkData, linkOptions, callback);
      }
    } else {
      this.modalRef.hide();
      this.modalRef = this.modalService.show(modal, Object.assign({}, {
        ignoreBackdropClick: true
      }));
    }
  }


  public formatPhoneNumber(tel) {
    if (!tel) { return ''; }

    var value = tel.toString().trim().replace(/^\+/, '');

    if (value.match(/[^0-9]/)) {
      return tel;
    }
    var country, city, number;

    switch (value.length) {
      case 10: // +1PPP####### -> C (PPP) ###-####
        country = 1;
        city = value.slice(0, 3);
        number = value.slice(3);
        break;

      case 11: // +CPPP####### -> CCC (PP) ###-####
        country = value[0];
        city = value.slice(1, 4);
        number = value.slice(4);
        break;

      case 12: // +CCCPP####### -> CCC (PP) ###-####
        country = value.slice(0, 3);
        city = value.slice(3, 5);
        number = value.slice(5);
        break;

      default:
        return tel;
    }

    if (country == 1) {
      country = "";
    }

    number = number.slice(0, 3) + '-' + number.slice(3);
    return (country + " (" + city + ") " + number).trim();
  };
}
