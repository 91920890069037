import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NewComicService } from '../new-comic.service';
import { Router } from '@angular/router';
import { PanelService } from '../services/panel/panel.service';
import { camelToHyphen, isAnonymousUser } from '../utilities/common.util';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ACTIONS } from '../constants/common.constants';

@Component({
  selector: 'app-template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.scss', '../payments/payments.component.scss']
})
export class TemplateCardComponent implements OnInit {
  @ViewChild('inviteFriendsModal', { static: false }) inviteFriendsModal;
  @Input() data: any;
  @Input() customStyles: any;
  public isSubscribePage: boolean;
  public isAnonymous: boolean;
  public isMobile: boolean;
  public inviteFriendsRef: any;

  constructor(
    private readonly newComicService: NewComicService,
    private readonly router: Router,
    private readonly panelService: PanelService,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
    this.isSubscribePage = this.newComicService.isSubscribePage();
    this.isMobile = this.newComicService.isMobileView();
    this.isAnonymous = isAnonymousUser();
  }

  redirectToUrl(url: string): void {
    if (url === 'inviteFriends') {
      this.openInviteFriendsModal(this.inviteFriendsModal);
      return;
    }

    // Add more actions to ACTIONS constant to ensure proper redirection for future cases
    if (ACTIONS.includes(url)) {
      const pageToRedirect = camelToHyphen(url);
      this.router.navigate([`/tinyview/${pageToRedirect}`]);
      return;
    }

    // Use the router to navigate to the specified URL
    if (url.indexOf('http') > -1) {
      window.location.href = url;
    } else {
      url = url.replace('/index.json', '');
      this.router.navigate([url]);
    }
  }

  public getImgUrl(data: any) {
    return this.panelService.getImgUrl(data.image, '/' + (data.series ? data.series : ''));
  }

  public openInviteFriendsModal(template: TemplateRef<any>) {
    const isMobile = this.isMobile && !this.isAnonymous ? ' mobile-modal' : '';
    this.inviteFriendsRef = this.modalService.show(template, Object.assign({}, {
      class: `modal-md${isMobile}`,
      ignoreBackdropClick: false,
      animation: !isMobile
    }));
  }

  public closeInviteFriendsModal() {
    this.inviteFriendsRef.hide();
  }

  public applyLH(): boolean {
    return this.data && this.data.title && this.data.description && (this.customStyles && this.customStyles.descriptionLH != 'normal' || !this.customStyles);
  }
}
