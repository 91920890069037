<div *ngIf="fetchedImg !== 'no-image'">
  <image-cropper
    [maintainAspectRatio]="true"
    [resizeToHeight]="420"
    [resizeToWidth]="800"
    [aspectRatio]="40/21"
    [imageBase64]="fetchedImg"
    [imageChangedEvent]="imageChangedEvent"
    (imageCropped)="croppedImage($event)"
    format="jpeg"
    imageQuality="60"
  ></image-cropper>
</div>

<div class="d-flex ml-auto mt-3 pt-1">
  <input type="file" class="file-input" (change)="fileChangeEvent($event)" accept="image/jpg,image/png,image/jpeg" />
  <button class="btn tv-btn-light">Use your own image</button>
</div>
