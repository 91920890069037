<div *ngIf="!errorMsg && !showTable" class="container custom-container-margin">
  <h1 class="display-heading-3 tag-color title-header">Main Dashboard</h1>
  <form [formGroup]="userForm" (submit)="onSubmit()" style="height: 70px;">
    <div>
      <div class="row">
        <div class="col-md-auto" style="padding: 0px !important;">
          <div class="form-group">
            <input [matDatepicker]="fromPicker" formControlName="dateFrom" (dateChange)="minDate = $event.value"
              readonly>
            <mat-datepicker-toggle [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
          </div>
        </div>
        <div class="col-md-auto" style="padding: 0px !important;">
          <div class="form-group">
            <input [matDatepicker]="toPicker" [min]="minDate" formControlName="dateTo" readonly>
            <mat-datepicker-toggle [for]="toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker color="primary"></mat-datepicker>
            <span class="text-danger">{{err}}</span>
          </div>
        </div>
        <div class="col-md-auto">
          <select class="form-select form-select-sm select-series" formControlName="seriesName" aria-label=".form-select-sm example">
            <option  *ngFor="let option of seriesData" value="{{option}}" >{{ option }}</option>
          </select>
        </div>
        <div>
          <div class="form-group">
            <button (click)="setUpdate(false)" type="submit" class="btn">Show</button>
          </div>
        </div>
        <!-- Removing Recalculate and Signout as per new creator dashboard requirement -->
        <!-- <div>
          <div class="form-group">
            <button (click)="setUpdate(true)" type="submit" class="btn recalculate-btn">Recalculate</button>
          </div>
        </div> -->
        <!-- <div class="col-3 text-right sign-out">
          <span class="toolbar-filler" (click)="authService.signOut()">Signout</span>
        </div> -->
      </div>
    </div>
  </form>
  <div >
    <div class="loader" *ngIf="isLoading">
    </div>
    <div *ngIf="!isLoading" class="table-responsive wrapper tableFixHead">
      <table class="table  table-striped user-list-table">
        <thead>
            <tr>
                <th colspan="1">Date</th>
                <th colspan="4">Users</th>
                <th colspan="4">Page Views</th>
                <th colspan="2">Influence Points</th>
                <th>IAP</th>
            </tr>
            <tr>
                <th colspan="0"></th>
                <th colspan="0">DAU</th>
                <th colspan="0">Signups</th>
                <th colspan="0">Guest Signups</th>
                <th colspan="0">Returning</th>
                <th colspan="0">Total</th>
                <th colspan="0">Web</th>
                <th colspan="0">iOS</th>
                <th colspan="0">Android</th>
                <th colspan="0">Earned</th>
                <th colspan="0">Consumed</th>
                <th colspan="0"></th>
            </tr>
        </thead>
        <tbody>
          <tr class="total-row">
            <td class="action-head" >Average</td>
            <td class="action-head">{{ (preparedTotals.dau/numberOfDays ? preparedTotals.dau/numberOfDays : 0) | number : '1.0-0' }}</td>
            <td class="action-head">{{ (preparedTotals.signups/numberOfDays ? preparedTotals.signups/numberOfDays : 0) | number : '1.0-0' }}</td>
            <td class="action-head">{{ (preparedTotals.anonymousSignups/numberOfDays ? preparedTotals.anonymousSignups/numberOfDays : 0) | number : '1.0-0' }}</td>
            <td class="action-head">{{ (preparedTotals.returningUsers/numberOfDays ? preparedTotals.returningUsers/numberOfDays : 0) | number : '1.0-0'}}</td>
            <td class="action-head">{{ preparedTotals.pageviews/numberOfDays  | number : '1.0-0'}}</td>
            <td class="action-head">{{ (preparedTotals.webPageviews/preparedTotals.pageviews ? preparedTotals.webPageviews/preparedTotals.pageviews : 0) | percent }}</td>
            <td class="action-head">{{ (preparedTotals.iOSPageviews/preparedTotals.pageviews ? preparedTotals.iOSPageviews/preparedTotals.pageviews : 0) | percent }}</td>
            <td class="action-head">{{ (preparedTotals.androidPageviews/preparedTotals.pageviews ? preparedTotals.androidPageviews/preparedTotals.pageviews : 0) | percent }}</td>
            <td class="action-head">{{ (preparedTotals.influencePointEarned/numberOfDays ? preparedTotals.influencePointEarned/numberOfDays : 0)  | number : '1.0-0' }}</td>
            <td class="action-head">{{ (preparedTotals.influencePointConsumed/numberOfDays ? preparedTotals.influencePointConsumed/numberOfDays : 0)  | number : '1.0-0' }}</td>
            <td class="action-head" >{{ (preparedTotals.IAP/numberOfDays ? preparedTotals.IAP/numberOfDays :0)  | number : '1.0-0'}}</td>
          </tr>
          <tr *ngFor="let item of preparedData | keyvalue: keyDescOrder">
            <td class="action-head date-time" (click)="showSeriesTable(item.value)" >{{ item.value.utcTime }}</td>
            <td class="action-head">{{ (item.value.dau ? item.value.dau : 0)   | number : '1.0-0' }}</td>
            <td class="action-head">{{ (item.value.signups ? item.value.signups : 0) | number : '1.0-0' }}</td>
            <td class="action-head">{{ (item.value.anonymousSignups ? item.value.anonymousSignups : 0) | number : '1.0-0' }}</td>
            <td class="action-head">{{ (item.value.returningUsers ? item.value.returningUsers : 0)  | number : '1.0-0' }}</td>
            <td class="action-head">{{((item.value.pageviews && item.value.pageviews > 0) ? item.value.pageviews : 0) | number : '1.0-0' }}</td>
            <td class="action-head">{{ ((item.value.pageviews && item.value.pageviews > 0) ? (item.value.webPageviews / item.value.pageviews) : 0)
              | percent }}</td>
              <td class="action-head">{{ ((item.value.pageviews && item.value.pageviews > 0) ? (item.value.iOSPageviews / item.value.pageviews) : 0)
                | percent }}</td>
                <td class="action-head">{{ ((item.value.pageviews && item.value.pageviews > 0) ? (item.value.androidPageviews / item.value.pageviews)
                  : 0) | percent }}</td>
            <td class="action-head">{{ item.value.influencePointEarned || 0 | number : '1.0-0' }}</td>
            <td class="action-head">{{ item.value.influencePointConsumed || 0 | number : '1.0-0' }}</td>
            <td class="action-head iap-count" (click)="item.value.IAP ? openIAPModal(IAPModal,item.value.giftItem,item.value.dateTime,true) : ''"  >{{ item.value.IAP ? item.value.IAP : 0 }}</td>
          </tr>
        </tbody>
    </table>
    <div class="current-date">
      <p>Calculated on {{comicService.formatDate(currentDate)}}</p>
    </div>
    </div>
  </div>
</div>


<div *ngIf="showTable" class="container custom-container-margin">
  <div class="show-heading">
    <p ><a (click)="showTable = false">Main Dashboard </a><span>> {{showDate}}</span></p>
  </div>
  <div class="loader" *ngIf="isLoading">
  </div>
  <div class="tableFixHead" *ngIf="hasData && newObj.length">
    <table  class="table table-striped">
      <thead>
          <tr>
              <th colspan="1">Series</th>
              <th colspan="5">Page Views</th>
              <th colspan="1">IAP</th>
          </tr>
          <tr>
            <th></th>
            <th>Total</th>
            <th>% of Total</th>
            <th>Web</th>
            <th>iOS</th>
            <th>Android</th>
            <th></th>
        </tr>
      </thead>
      <tbody style="text-align: right;">
        <tr *ngFor="let item of newObj">
          <td class="action-head">{{ item.key }}</td>
          <td class="action-head">{{ (item.data.pageviews ? item.data.pageviews : 0 ) | number : '1.0-0' }}</td>
          <td class="action-head">{{((item.data.pageviews && singleDateTable && item.data.pageviews > 0) ? item.data.pageviews/singleDateTable : 0 ) | percent}}</td>
          <td class="action-head">{{ ((item.data.pageviews && singleDateTable && item.data.pageviews > 0) ? (item.data.webPageviews / item.data.pageviews) : 0)
            | percent}}</td>
          <td class="action-head">{{ ((item.data.pageviews && singleDateTable && item.data.pageviews > 0) ?  (item.data.iOSPageviews/ item.data.pageviews) : 0) | percent}}</td>
          <td class="action-head">{{ ((item.data.pageviews && singleDateTable && item.data.pageviews > 0) ? (item.data.androidPageviews/ item.data.pageviews) : 0) | percent }}</td>
          <td class="action-head iap-count" (click)="item.IAP ? openIAPModal(IAPModal,item.giftCount,showDate,false) :''">{{ item.IAP ? item.IAP : 0 }}</td>
        </tr>
      </tbody>
  </table>
  <div class="current-date">
    <p>Calculated on {{comicService.formatDate(currentDate)}}</p>
  </div>
  </div>
<h4 *ngIf="!hasData && !newObj.length" style="color: black; text-align: center;">No Data</h4>
</div>

<ng-template #IAPModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">In-App Purchases</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="IAPModalRef.hide()">
       <img src="../../assets/images/new-icons/icons8-delete-100.png" class="cross-btn" />
    </button>
 </div>
 <p class="show-time">{{showDateInGift}}</p>
  <table class="table table-striped gift-table">
    <thead>
        <tr>
            <th colspan="1">In App Purchases</th>
            <th colspan="1">How many</th>
        </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of giftData | keyvalue">
        <td class="action-head">{{ getGiftTitle(item.key) }}</td>
        <td class="action-head">{{ item.value }}</td>
      </tr>
    </tbody>
</table>
</ng-template>

<app-data-not-found *ngIf="errorMsg && !isLoading"></app-data-not-found>