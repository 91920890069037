<div *ngIf="comicData?.button">
    <ng-container *ngIf="!checkActionType && !isWebsite() && comicAction">
        <a [routerLink]="comicAction"><button type="button" class="app-btn">
                {{comicData.button}}
            </button></a>
    </ng-container>
    <ng-container *ngIf="isWebsite() && !checkActionType">
        <a  target="_blank" (click)="getViewsForExternalLink()"><button type="button"
                class="app-btn">
                {{comicData.button}}</button></a>
    </ng-container>
    <ng-container *ngIf="checkActionType">
        <button (click)="navigateByActions()" type="button" class="app-btn">{{comicData.button}}</button>
    </ng-container>
</div>
