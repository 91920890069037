<div class="header" *ngIf="!isMobile">
  <!-- Header content goes here -->
  <app-tv-header></app-tv-header>
</div>

<div class="container" [ngClass]="{'mobile-container': isMobile}">
  <div class="sidebar" *ngIf="loadThisComponent('sidebar')">
    <!-- Sidebar content goes here -->
    <app-sidebar></app-sidebar>
  </div>

  <div #mainContent class="main-content" (scroll)="isScrolling($event, mainContent)">
    <router-outlet></router-outlet>
  </div>

  <div #notificationContent class="notification" *ngIf="loadThisComponent('notifications-web')" (scroll)="isScrolling($event, notificationContent)">
    <!-- Notification content goes here -->
    <app-notifications></app-notifications>
  </div>

</div>

<!-- Bottom navigation here -->
<div *ngIf="loadThisComponent('bottom-nav-mobile')" class="sticky-footer">
  <app-bottom-nav-mobile></app-bottom-nav-mobile>
</div>
<img class="mobile-back-arrow" [class.dark]="navIconImg === backIconSRC" *ngIf="!newComicService.isHomePage() && isMobile"
  [src]="navIconImg" (click)="goBack()">
