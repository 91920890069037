<ng-container *ngFor="let data of chapter">
    <div *ngIf="data.template === 'subscribers'" class="tv-card" [class.my-0]="((currentUrl == subscribeURL) && !topLevelSubscription) || isHomePage"
        [ngClass]="{'card-border': isDirectoryPage}">
        <div class="bar-sub-container">
            <div class="sub-container">
                <ng-container *ngIf="!data?.image">
                    <h2 class="pb-3" *ngIf="data?.title">{{data.title}}</h2>
                    <p class="pb-3" *ngIf="data?.description">{{data.description}}</p>
                    <div>
                        <ng-container *ngIf="data.goalType === 'subscribers'; else revenueBlock">
                            <h2 class="d-inline">
                                {{ renewCount | number:'1.0-0':'en-US' }}
                            </h2>
                            <p class="d-inline">
                                subscribers of {{data.goalSubscribers | number:'1.0-0':'en-US'}} goal.
                            </p>
                        </ng-container>
    
                        <ng-template #revenueBlock>
                            <h2 class="d-inline">
                                ${{ totalAggregates | number:'1.0-0':'en-US' }}
                            </h2>
                            <p class="d-inline">
                                raised of ${{data.goal | number:'1.0-0':'en-US'}} monthly goal.
                            </p>
                        </ng-template>
                    </div>
                    <div class="bar my-2">
                        <div class="progress h-50" *ngIf="data.goal && totalAggregates">
                            <progress id="progress-bar" class="progress-bar" role="progressbar"
                                [value]="data.goalType === 'subscribers' ? renewCount : totalAggregates"
                                [max]="data.goalType === 'subscribers' ? data.goalSubscribers : data.goal"></progress>
                        </div>
                    </div>
                    <div class="subscribe-block">
                        <div class="subscribe-data">
                            <p>
                                {{ data.goalType === 'subscribers'
                                ? ('$' + (totalAggregates | number:'1.0-0' : 'en-US') + ' monthly revenue')
                                : (renewCount | number:'1.0-0':'en-US') + ' subscribers' }}
                            </p>
                            <div class="div2 mt-3"
                                *ngIf="showButton(data.button) && (currentUrl != '/tinyview/subscribe') && data.button">
                                <button type="button" (click)="navigate(data.button)" class="btn tv-btn-red">
                                    {{ data.button }}
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="data?.image">
                    <img [src]="getImgUrl(data)" />
                </ng-container>
            </div>
        </div>
    </div>

    <div *ngIf="!['subscribers', 'card', templateTypes.carousel].includes(data.template) && renderImagePanel">
        <app-image [comicData]="data"></app-image>
    </div>

    <app-template-card *ngIf="data.template === 'card' && !accessAllowed && data.action != 'manageAlerts'" [data]="data" [customStyles]="{ descriptionLH: 'normal' }"></app-template-card>
    <app-template-card *ngIf="data.template === 'card' && actions.includes(data.action)" [data]="data" [customStyles]="{ descriptionLH: 'normal', 'mt-0': !isDirectoryPage && !isComicPage }"></app-template-card>
    
    <div *ngIf="data.template === templateTypes.carousel && data.list !== 0 && !isSubscribePage">
        <app-scroller [panelConfigData]="data"></app-scroller>
    </div>
</ng-container>
