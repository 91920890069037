import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComicService } from '../services/comic.service';
import { Router } from '@angular/router';
import { NewComicService } from '../new-comic.service';

@Component({
  selector: 'app-comic-navigation',
  templateUrl: './comic-navigation.component.html',
  styleUrls: ['./comic-navigation.component.scss']
})
export class ComicNavigationComponent implements OnInit {
  @Input() seriesEpisodes;
  @Input() series;
  @Input() action;
  // for banner at botttom
  @Input() currentSeriesDetails: any;
  @Input() followBtnText: string;
  @Output() onPropertyChange = new EventEmitter();

  public currentURL: string;

  public disableImages = {
    firstComic: true,
    prevComic: true,
    nextComic: true,
    lastComic: true
  };

  public imageNavigation = {
    firstComic: '',
    prevComic: '',
    nextComic: '',
    lastComic: ''
  };
  public isStoryPage: boolean;
  seriesName: string;

  constructor(
    private comicService: ComicService,
    private newComicService: NewComicService,
    private readonly router: Router,
  ) { }

  ngOnInit() {
    this.currentURL = this.comicService.getCurrentUrl();
    this.isStoryPage = this.newComicService.isStoryPage();

    const urlToMatch = this.isStoryPage ? this.action : this.currentURL;
    const index = this.seriesEpisodes.findIndex(episodes => episodes.action.indexOf(urlToMatch) > -1);

    // Check if there is an element before it
    this.disableImages.prevComic = (index > -1) ? !!!this.seriesEpisodes[index - 1] : true;

    // Check if there is an element after it
    this.disableImages.nextComic = (index > -1) ? !!!this.seriesEpisodes[index + 1] : true;

    // Setting action url on images
    this.imageNavigation.prevComic = !this.disableImages.prevComic ? this.seriesEpisodes[index - 1].action : '';
    this.imageNavigation.nextComic = !this.disableImages.nextComic ? this.seriesEpisodes[index + 1].action : '';
    this.seriesName = this.isStoryPage ? this.series : this.currentURL.split('/')[1];
    this.getNavigation();
  }

  async getNavigation() {
    const urlToMatch = this.isStoryPage ? this.action : this.currentURL;
    const input = {
      series: this.seriesName
    };
    const res = await this.comicService.getComicNavigation(input);

    // Check if first and latest element are not equal to currentURL
    this.disableImages.firstComic = res.data && res.data.first && res.data.first.action && (res.data.first.action.indexOf(urlToMatch) > -1);
    this.disableImages.lastComic = res.data && res.data.latest && res.data.latest.action && (res.data.latest.action.indexOf(urlToMatch) > -1);

    // Setting action url on images
    this.imageNavigation.firstComic = !this.disableImages.firstComic ? res.data.first.action : '';
    this.imageNavigation.lastComic = !this.disableImages.lastComic ? res.data.latest.action : '';
  }

  public navigateTo(actionURL: string) {
    if (!actionURL) return;

    if (this.newComicService.isExternalImage(actionURL)) {
      this.router.navigate(['/story/action'], { queryParams: { actionURL } });
    } else {
      actionURL = actionURL.replace('/index.json', '');
      this.router.navigate([actionURL]);
    }
  }

  onBannerChange(event) {
    const data = { likeCount: event.likeCount, followBtnText: event.followBtnText }
    this.onPropertyChange.emit(data);
  }
}
