import { Injectable } from '@angular/core';
import { LOGIN_FLOW } from '../constants/common.constants';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    public authService: AuthService,
    private router: Router
  ) {}

    navigateTo(url, currentFlow, queryParams) {
      if ([LOGIN_FLOW.SIGNIN].includes(currentFlow)) url = `signin/${url}`;
      if ([LOGIN_FLOW.SIGNUP].includes(currentFlow)) url = `signup/${url}`;
      if ([LOGIN_FLOW.ALERTS].includes(currentFlow)) url = `verification/${url}`;
        this.router.navigate([url], { queryParams });
    }

    getCurrentFlow(url: string) {
      // Define the regex pattern for matching /signin/* URLs
      const signinPattern = /^\/signin(?:\/[^\/?]+)*\/?(?:\?.*)?$/;
      // Define the regex pattern for matching /signup/* URLs
      const signupPattern = /^\/signup(?:\/[^\/?]+)*\/?(?:\?.*)?$/;
      // Define the regex pattern for matching /signup/* URLs
      const alertsPattern = /^\/verification(?:\/[^\/?]+)*\/?(?:\?.*)?$/;
  
      // Check if the URL matches the pattern
      if (signinPattern.test(url)) {
        return LOGIN_FLOW.SIGNIN;
      } else if (signupPattern.test(url)) {
        return LOGIN_FLOW.SIGNUP;
      } else if (alertsPattern.test(url)) {
        return LOGIN_FLOW.ALERTS;
      }
    }
}
