export const shareConfig = [
  {
    title: 'Messages',
    action: 'openSMS',
    image: '../../assets/images/apple-messages-100.png',
    androidImage: '../../assets/images/google-messages-100.png',
    show: true
  },
  {
    title: 'WhatsApp',
    action: 'openWhatsApp',
    image: '../../assets/images/whatsapp-100.png',
    show: true
  },
  {
    title: 'Instagram',
    action: 'openInstagram',
    image: '../../assets/images/instagram-100.png',
    show: true
    // show: !this.isDesktop
  },
  {
    title: 'Facebook',
    action: 'openFacebook',
    image: '../../assets/images/facebook-100.png',
    show: true
  },
  {
    title: 'X',
    action: 'openTwitter',
    image: '../../assets/images/new-icons/icons8-twitterx-dark-100-cropped.png',
    show: true
  },
  {
    title: 'Copy link',
    action: 'copyLink',
    image: '../../assets/images/copy-link-pink-100.png',
    show: true
  },
  // 6th index: More option, if the index is changed then make sure to map the correct index in panel-nav.component.ts
  {
    title: 'More',
    action: 'openSystemDefaultShare',
    image: '../../assets/images/options-100.png',
    show: true
  }
];

export const INFLUENCE_PAGE = {
  title: 'Influence Points',
  image: 'https://storage.googleapis.com/tinyview-d78fb.appspot.com/tinyview/influence-points/preview.jpg',
  image2: 'https://storage.googleapis.com/tinyview-d78fb.appspot.com/tinyview/influence-points/invite-and-share.jpg',
  description: `Can't subscribe but still want to support creators? Invite friends or share Tinyview comics with your friends & family and earn influence points. Then use your points to read comics and unlock bonus panels.`,
  url: '/tinyview/influence-points',
  previewImg: 'https://storage.googleapis.com/tinyview-d78fb.appspot.com/tinyview/influence-points/preview.jpg',
  profilePic: 'https://storage.googleapis.com/tinyview-d78fb.appspot.com/tinyview/tinyview-profile.jpg'
}
