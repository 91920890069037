import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComicService } from '../services/comic.service';

@Component({
  selector: 'app-comic-buttons',
  templateUrl: './comic-buttons.component.html',
  styleUrls: ['./comic-buttons.component.scss']
})
export class ComicButtonsComponent implements OnInit {
  currentUrl: string;
  currentDevice: string;
  comicAction: any;
  @Input() comicData;
  checkActionType: boolean;
  constructor(
    private readonly comicService: ComicService, private router: Router) {
    this.currentUrl = this.comicService.getCurrentUrl();
    this.currentDevice = this.comicService.getOperatingSystem();
  }

  ngOnInit() {
    this.comicAction = this.comicService.getComicAction(this.comicData);
    this.checkActionType = this.checkAction(this.comicData);
  }

  getViewsForExternalLink() {
    this.comicService.viewsForExternalLink(this.comicAction);
    window.open(this.comicAction, '_blank');
  }

  openNewTab(url: string) {
    window.open("//" + url, '_blank');
  }

  public checkAction(comicData) {
    if (comicData.action == 'download-app-android' || comicData.action == 'download-app-ios' || comicData.action == 'share' || comicData.action == 'restore-purchases') {
      return true;
    } else {
      return false;
    }
  }

  public navigateByActions() {
    this.comicService.navigateByActions(this.comicData);
  }

  public isWebsite(): boolean {
    return this.comicData.actionType === 'website';
  }
}
