<div class="icon-container">
    <!-- <a aria-current="page" (click)="backClicked()">
        <img class="icon" *ngIf="!backDisabled" src="../../assets/images/back-arrow-icon.png" alt="">
        <img class="icon" *ngIf="backDisabled" src="../../assets/images/left-arrow-disabled.png" alt="">
        <br />
    </a> -->
    <!-- <a aria-current="page" [ngClass]="{'disabled' : !series}" [href]="series">
        <img class="icon" *ngIf="series" src="../../assets/images/icons8-red-bulleted-list-25.png" alt="">
        <img class="icon" *ngIf="!series" src="../../assets/images/icons8-grey-bulleted-list-25.png" alt="">
        <br />
    </a> -->
    <a aria-current="page" [title]="series" [ngClass]="{'disabled' : !series}" [routerLink]="'/' + seriesName" *ngIf="(currentUrl != '/tinyview/comic-series-directory' && currentUrl != '/') && seriesImage" >
        <img class="icon series"  *ngIf="series && seriesImage" [src]="seriesImage" alt="" />
        <img class="icon series-grey"  *ngIf="!series && seriesImage" [src]="seriesImage" alt="" />
        <br/>
      </a>
      <a title="Tinyview" routerLink="/tinyview/comic-series-directory" *ngIf="(currentUrl == '/tinyview/comic-series-directory' || currentUrl == '/')">
        <img class="icon series" *ngIf="currentUrl == '/'"  src="https://storage.googleapis.com/tinyview-dev.appspot.com/tinyview/tinyview-profile.jpg" alt="" />
        <img class="icon series-grey" *ngIf="currentUrl == '/tinyview/comic-series-directory'"  src="https://storage.googleapis.com/tinyview-dev.appspot.com/tinyview/tinyview-profile.jpg" alt="" />
      </a>
    <a aria-current="page" (click)="onDirectoryPage()" >
        <img class="icon" *ngIf="currentUrl != '/tinyview/comic-series-directory'"
            src="../../assets/images/directory-icon.png" alt="">
        <img class="icon" *ngIf="currentUrl == '/tinyview/comic-series-directory'"
            src="../../assets/images/directory-icon-disabled.png" alt="">
        <br />
    </a>
    <a aria-current="page" (click)="onHomePage()">
        <img class="icon" src="./assets/images/home-icon.png" alt="">
        <br />
    </a>
    <a aria-current="page" (click)="reloadPage()">
        <img class="icon" src="../../assets/images/reload.png" alt="">
        <br />
    </a>
</div>