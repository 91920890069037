<div class="action-container">
    <div class="text-center mb-2">
        <h2 class="mb-2">We’re unable to reach you</h2>
        <div *ngIf="!tinyviewPage">
          <p *ngIf="isFollowingAlready" class="body-mini">You’re following {{series.title}} but we’re unable to reach you. Manage alerts and know when {{series.title}} publishes a new comic.</p>
          <p *ngIf="!isFollowingAlready" class="body-mini">You want to follow {{series.title}} but we have no way of reaching you. Manage alerts and know when {{series.title}} publishes a new comic.</p>
        </div>
        <div *ngIf="tinyviewPage">
          <p *ngIf="isFollowingAlready" class="body-mini">You’re following Tinyview but we’re unable to reach you. Manage alerts and get updates from Tinyview.</p>
          <p *ngIf="!isFollowingAlready" class="body-mini">You want to follow Tinyview but we have no way of reaching you. Manage alerts and let us know how to send you updates from Tinyview.</p>
        </div>
    </div>
    <div>
      <div class="text-center ml-4 mt-3 pb-0">
        <button class="tv-btn-light btn" aria-label="Open" (click)="closeModal(isFollowingAlready)">
          Not Now
        </button>
        <button class="btn tv-btn-red ml-3" aria-label="Open" (click)="onManageAlertClick()">
            Manage Alerts
        </button>
        </div>
    </div>
</div>