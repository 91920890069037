import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ComicService } from '../services/comic.service';
import { Location } from '@angular/common';
import { StoryFeedService } from '../services/story-feed.service';
@Component({
  selector: 'app-bottom-comic-nav',
  templateUrl: './bottom-comic-nav.component.html',
  styleUrls: ['./bottom-comic-nav.component.scss'],
})

export class BottomComicNavComponent implements OnInit {
  @Input() homeRoute: string;
  @Output() clicked: EventEmitter<any> = new EventEmitter;
  @Input() isLiked;
  @Input() likeCount: any;
  @Input() viewCount: any;
  @Input() seriesImage: string;
  @Output() addRemoveLike: EventEmitter<any> = new EventEmitter;
  currentDevice: string;
  showShare: boolean = false;
  series: string = null;
  followBtnText: string;
  isHome: boolean = false;
  isDirectory: boolean = false;
  backDisabled: boolean = false;
  currentUrl: string;
  isCover: any;
  isEmpty: boolean;
  seriesName: string;
  constructor(
    private readonly comicService: ComicService,
    private readonly router: Router,
    public feedService: StoryFeedService,
    private _location: Location,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platform: object
  ) { }

  shareData = {
    title: 'Tinyview',
    text: this.comicService.toTitleFormat(/[^/]*$/.exec(this.router.url)[0]),
    url: 'https://tinyview.com' + this.router.url,
  };

  ngOnInit() {
    this.currentUrl = this.comicService.getCurrentUrl();
    this.isCover = this.comicService.isCoverPage();
    if (isPlatformBrowser(this.platform)) {
      this.backDisabled = window.history.length <= 1 ? true : false;
    }
    this.currentDevice = this.comicService.getOperatingSystem();
    const param = this.route.snapshot.paramMap.get('ID');
    this.followBtnText = this.isLiked ? 'Following' : 'Follow';
    if (param) {
      this.feedService.getStoryByID(param).then(async resp => {
        this.series = resp.data && resp.data.data && resp.data.data.user && resp.data.data.user.name ? resp.data.data.user.name : '';
        if (resp.data && resp.data.data && resp.data.data.action)
          this.seriesName = this.comicService.formatSeriesName(resp.data.data.action).replace('/footer.json', '');
      })
    } else if (!this.comicService.isCoverPage()) {
      this.comicService.getComicTitle().subscribe(
        (resp) => {
          for (var key in resp) {
            if (this.currentUrl.includes(key) && !this.currentUrl.includes('/tinyview/comic-series-directory')) {
              this.series = resp[key].title;
              this.seriesName = key;
            }
          }
        });
    }
  }

  public backClicked() {
    if (isPlatformBrowser(this.platform)) {
      if (window.history.length > 1) {
        this._location.back();
      } else {
        this.backDisabled = true;
      }
    }
  }

  public reloadPage() {
    location.reload();
  }

  onShare() {
    if (this.currentDevice == 'androidBrowser' || this.currentDevice == 'iosBrowser') {
      this.share();
    } else {
      this.showShare = !this.showShare;
    }
  }

  public onHomePage() {
    if (isPlatformBrowser(this.platform)) {
      if (this.currentUrl == '/') {
        var sp = window.scrollY;
        if (sp != 0) {
          window.scrollTo(0, 0);
        } else if (this.currentUrl == '/') {
          window.location.reload();
        }
      } else {
        window.scrollTo(0, 0);
        this.router.navigate(['/']);
      }
    }
  }

  public onDirectoryPage() {
    if (isPlatformBrowser(this.platform)) {
      if (this.currentUrl != '/tinyview/comic-series-directory') {
        this.router.navigate(['/tinyview/comic-series-directory']);
      }
    }
  }

  async share() {
    let newVariable: any;
    if (isPlatformBrowser(this.platform) && window && window.navigator) {
      newVariable = window.navigator;
      if (newVariable && newVariable.share) {
        try {
          await newVariable.share(this.shareData);
        } catch (err) {
        }
      }
    }
  }

  public shareFacebook(): void {
    if (isPlatformBrowser(this.platform)) {
      const url = encodeURI(document.location.href);
      const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&utmSource=facebook&utmContent=referral&utmTerm=topbar&referrer=facebook`;
      this.comicService.popUpUrl(shareUrl);
    }
  }

  public shareTwitter(): void {
    if (isPlatformBrowser(this.platform)) {
      const url = encodeURI(document.location.href);
      const shareUrl = `https://www.twitter.com/intent/tweet?url=${url}&via=TinyviewComics&utmSource=twitter&utmContent=referral&utmTerm=topbar&referrer=twitter`;
      this.comicService.popUpUrl(shareUrl);
    }
  }
}
